
import Component, { mixins } from 'vue-class-component';
import UsesCanvasPeriod from '@/components/shared/mixins/UsesCanvasPeriod';
import UsesCanvasRectangles from '@/components/shared/mixins/UsesCanvasRectangles';
import { Prop, Watch } from 'vue-property-decorator';
import Interruption from '@/core/interfaces/Interruption';
import unique from 'array-unique';
import {
    addDays, areIntervalsOverlapping, getDay, parseISO, setHours, setMinutes,
} from 'date-fns';
import { Getter } from 'vuex-class';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleType from '@/core/interfaces/VehicleType';
import UsesVehicleTypeColors from '@/components/shared/mixins/UsesVehicleTypeColors';
import Fence from '@/core/interfaces/Fence';
import PauzesPopover from '@/components/shared/Pauzes/PauzesPopover.vue';
import Unavailability from '@/core/interfaces/Unavailability';

const LANE_HEIGHT = 25;

const STYLE = 'rgb(253,53,53)';
const STYLE_HOVERING = 'rgb(133,12,24)';

type HoveringItem = {
    interruption?: Interruption,
    unavailability?: Unavailability,
};

@Component({
    components: { PauzesPopover },
})
export default class InterruptionsTimeline extends mixins<UsesCanvasPeriod,
    UsesCanvasRectangles<HoveringItem>,
    UsesVehicleTypeColors>(UsesCanvasPeriod, UsesCanvasRectangles, UsesVehicleTypeColors) {
    @Prop({ required: true })
    interruptions!: Interruption[];

    @Prop({ default: 'vehicle-type' })
    unavailabilityLaneMode!: 'vehicle-type' | 'fence';

    @Prop({ default: () => [] })
    unavailabilities!: Unavailability[];

    @Prop({ required: true })
    period!: { start: Date, end: Date };

    @Prop({ default: 0, type: Number })
    marginLeft!: number;

    @Prop({ type: Number })
    fenceId!: number;

    @Getter('vehicleById')
    vehicleById!: (id: number) => Vehicle;

    @Getter('fenceById')
    fenceById!: (id: number) => Fence;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    laneObjects: any[] = [];

    @Watch('interruptions')
    @Watch('unavailabilities')
    async draw(): Promise<void> {
        this.clearHoveringRectangles();
        this.clearCanvas();
        this.laneObjects = [];
        this.drawUnavailabilities();
        this.drawInterruptions();
    }

    get calculatedCanvasHeight(): number {
        return 1 + this.numberOfLanes * LANE_HEIGHT;
    }

    @Watch('hovering')
    drawInterruptions(): void {
        const ctx = this.context;

        this.interruptions.forEach(interruption => {
            const laneStart = this.getLaneStartY(`vehicle-${interruption.vehicle_id}`);

            const xStart = this.getXPixelForTime(parseISO(interruption.started_at));
            const xEnd = this.getXPixelForTime(parseISO(interruption.ended_at));
            const centerY = laneStart + (LANE_HEIGHT / 2);

            const style = this.hovering?.value.id === interruption.id ? STYLE_HOVERING : STYLE;

            this.drawCircle(xStart, centerY, 5, style);
            this.drawCircle(xEnd, centerY, 5, style);

            ctx.beginPath();
            ctx.fillStyle = '';
            ctx.moveTo(xStart, centerY);
            ctx.lineTo(xEnd, centerY);
            ctx.strokeStyle = style;
            ctx.lineWidth = 5;
            ctx.stroke();

            if (!this.hovering) {
                this.registerHoverRectangle({
                    x: xStart,
                    y: laneStart,
                    w: xEnd - xStart,
                    h: LANE_HEIGHT,
                }, { interruption });
            }
        });
    }

    drawUnavailabilities(): void {
        if (!this.chartUnavailabilities.length) {
            return;
        }

        const ctx = this.context;
        ctx.font = '12px Ubuntu';

        this.chartUnavailabilities.forEach(unavailability => {
            ctx.beginPath();
            ctx.fillStyle = 'rgba(195, 10, 20, .1)';

            const laneStart = this.getLaneStartY(this.unavailabilityLaneMode === 'vehicle-type'
                ? `vehicle-type-${unavailability.item.vehicle_type_id}`
                : `fence-${unavailability.item.fences.sort().join('-')}`);

            const xStart = this.getXPixelForTime(unavailability.start);
            const xEnd = this.getXPixelForTime(unavailability.end);
            const w = xEnd - xStart;

            let text = this.vehicleTypeById(unavailability.item.vehicle_type_id)?.name;

            if (this.unavailabilityLaneMode === 'fence') {
                text = `${text} - ${unavailability.item.fences.map(fenceId => this.fenceById(fenceId)?.name || '').join(', ')}`;
            }

            const textWidth = ctx.measureText(text).width + 10;
            const textSectionWidth = textWidth + 20;

            ctx.rect(xStart, laneStart, w, 20);
            ctx.setLineDash([]);
            ctx.lineWidth = 0;
            ctx.strokeStyle = 'rgba(195, 10, 20, .5)';
            ctx.fill();
            ctx.stroke();

            const centerX = xStart + (w / 2);

            if (textSectionWidth < w) {
                ctx.beginPath();
                ctx.fillStyle = 'rgba(255, 255, 255, .4)';
                ctx.fillRect(centerX - (textWidth / 2) - 10, laneStart + 1, textWidth + 20, 18);
                ctx.fill();

                ctx.beginPath();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'hanging';
                ctx.font = '12px Ubuntu';
                ctx.fillStyle = this.vehicleTypeColors[unavailability.item.vehicle_type_id]?.stroke;
                ctx.fillText(text, centerX + 6, laneStart + 6, w - 20);
                ctx.fill();

                ctx.beginPath();
                ctx.font = '10px icons';
                ctx.fillText('\uF1A2', centerX - (textWidth / 2) - 3, laneStart + 7);
                ctx.fill();
            }

            if (!this.hovering) {
                this.registerHoverRectangle({
                    x: xStart,
                    y: laneStart,
                    w: xEnd - xStart,
                    h: LANE_HEIGHT,
                }, { unavailability: unavailability.item });
            }
        });
    }

    get chartUnavailabilities(): { start: Date, end: Date, item: Unavailability }[] {
        let day = 0;
        let result: { start: Date, end: Date, item: Unavailability }[] = [];
        const idsProcessed: number[] = [];

        while (day < this.daysInChart) {
            const date = addDays(this.period.start, day);
            const unavailabilitiesForWeekday = this.unavailabilities
                .filter(item => item.weekdays.includes(getDay(date)))
                .sort((a, b) => Number(a.time_from.replace(':', ''))
                    - Number(b.time_from.replace(':', '')))
                .map(item => {
                    if (idsProcessed.includes(item.id)) {
                        return null;
                    }
                    const from = item.time_from.split(':').map(n => Number(n));
                    const end = item.time_to.split(':').map(n => Number(n));
                    const fromDate = setMinutes(setHours(new Date(), from[0]), from[1]);
                    const endDate = setMinutes(setHours(new Date(), end[0]), end[1]);

                    const overlapping = this.unavailabilities
                        .filter(u => u.enabled)
                        .sort((a, b) => Number(a.time_from.replace(':', ''))
                            - Number(b.time_from.replace(':', '')))
                        .filter(u => u.weekdays.includes(getDay(date)))
                        .filter(u => u.fences.some(fenceId => item.fences.includes(fenceId)))
                        .filter(u => u.vehicle_type_id === item.vehicle_type_id)
                        .filter(u => {
                            const fromU = u.time_from.split(':').map(n => Number(n));
                            const endU = u.time_to.split(':').map(n => Number(n));

                            const fromDateU = setMinutes(setHours(new Date(), fromU[0]), fromU[1]);
                            const endDateU = setMinutes(setHours(new Date(), endU[0]), endU[1]);

                            return areIntervalsOverlapping({
                                start: fromDate,
                                end: endDate,
                            },
                            {
                                start: fromDateU,
                                end: endDateU,
                            });
                        });

                    idsProcessed.push(...overlapping.map(itemO => itemO.id));

                    const froms = overlapping.map(itemO => itemO.time_from).sort();

                    if (froms.length === 0) {
                        return null;
                    }

                    const ends = overlapping.map(itemO => itemO.time_to).sort().reverse();
                    const earliestFrom = froms[0].split(':').map(n => Number(n));
                    const latestEnd = ends[0].split(':').map(n => Number(n));

                    return {
                        start: setMinutes(setHours(date, earliestFrom[0]), earliestFrom[1]),
                        end: setMinutes(setHours(date, latestEnd[0]), latestEnd[1]),
                        item,
                    };
                })
                .filter(a => a !== null) as { start: Date, end: Date, item: Unavailability }[];

            result = [...result, ...unavailabilitiesForWeekday];

            day += 1;
        }

        return result;
    }

    get numberOfLanes(): number {
        return unique(this.interruptions.map(interruption => interruption.vehicle_id)).length
            + unique(this.chartUnavailabilities.map(u => (this.unavailabilityLaneMode === 'vehicle-type'
                ? u.item.vehicle_type_id
                : u.item.fences.sort().join('-')))).length;
    }

    get start(): Date {
        return this.period.start;
    }

    get end(): Date {
        return this.period.end;
    }

    get hoveringVehicle(): Vehicle | null {
        if (!this.hovering?.value?.interruption) {
            return null;
        }

        return this.vehicleById(this.hovering.value.interruption.vehicle_id);
    }

    get leftMargin(): number {
        return this.marginLeft;
    }

    getLaneStartY(key: string): number {
        if (!this.laneObjects.includes(key)) {
            this.laneObjects.push(key);
        }

        return 1 + (this.laneObjects.indexOf(key) * LANE_HEIGHT);
    }
}

