import { MutationTree } from 'vuex';
import { AfxState } from '@/store/modules/afx/afxState';
import { AfxBarnZone } from '@/core/interfaces/AfxBarnZone';
import { AfxBarnZoneScheduledBlock } from '@/core/interfaces/AfxBarnZoneScheduledBlock';

const mutations: MutationTree<AfxState> = {
    setBarnZones: (state: AfxState, payload: AfxBarnZone[]) => {
        state.barnZones = payload;
    },

    setBarnZoneScheduledBlocks: (state: AfxState, payload: AfxBarnZoneScheduledBlock []) => {
        state.barnZoneScheduledBlocks = payload;
    },

    setBarnZoneManualBlocked: (state: AfxState, payload: { barnZoneId: number, until: string | null }) => {
        state.barnZonesManualBlocked = state.barnZonesManualBlocked
            .filter(barnZone => barnZone.barnZoneId !== payload.barnZoneId);

        state.barnZonesManualBlocked.push(payload);
    },
};

export default mutations;
