import { RootState } from '@/store/rootState';
import { createRecordByIdFromArray } from '@/store/helpers';
import Farm from '@/core/interfaces/Farm';
import FeedType from '@/core/interfaces/FeedType';
import Fence from '@/core/interfaces/Fence';
import EstimatedFeedHeight from '@/core/interfaces/EstimatedFeedHeight';
import Vue from 'vue';
import Barn from '@/core/interfaces/Barn';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleType from '@/core/interfaces/VehicleType';
import Ration from '@/core/interfaces/Ration';
import EnabledModules from '@/core/interfaces/EnabledModules';
import VehicleAction from '@/core/interfaces/VehicleAction';
import LinkedFence from '@/core/interfaces/LinkedFence';
import PresetRun from '@/core/interfaces/PresetRun';
import VehicleStatus from '@/core/interfaces/VehicleStatus';
import PlanningSettings from '@/core/interfaces/PlanningSettings';
import FarmPlanner from '@/core/interfaces/FarmPlanner';
import Unavailability from '@/core/interfaces/Unavailability';
import RunsFilterPreferences from '@/core/interfaces/RunsFilterPreferences';

export const setUserId = (state: RootState, payload: number): void => {
    state.userId = payload;

    Vue.gtm.trackEvent({
        event: 'login',
        userId: Number(payload),
    });
};

export const setFarmId = (state: RootState, payload: number): void => {
    state.farmId = Number(payload);

    Vue.gtm.trackEvent({
        event: 'setFarm',
        farmId: Number(payload),
    });
};

export const clear = (state: RootState): void => {
    state.farmId = null;
    state.enabledModules = null;
    state.userId = null;
};

export const setFarms = (state: RootState, payload: Farm[]): void => {
    state.farms = createRecordByIdFromArray(payload);
    state.farmsList = payload.map(item => item.id);
};

export const setFences = (state: RootState, payload: Fence[]): void => {
    state.fences = createRecordByIdFromArray(payload);
    state.fencesList = payload.map(item => item.id);
};

export const updateLinkedFence = (state: RootState, payload: LinkedFence): void => {
    if (payload.id) {
        Vue.set(state.linkedFences, state.linkedFences.findIndex(f => f.id === payload.id), payload);
    } else {
        state.linkedFences.push(payload);
    }
};

export const removeLinkedFence = (state: RootState, payload: number): void => {
    state.linkedFences = state.linkedFences.filter(f => f.id !== payload);
};

export const setLinkedFences = (state: RootState, payload: LinkedFence[]): void => {
    state.linkedFences = payload;
};

export const setEnabledModules = (state: RootState, payload: EnabledModules): void => {
    state.enabledModules = payload;
};

export const setBarns = (state: RootState, payload: Barn[]): void => {
    state.barns = createRecordByIdFromArray(payload);
    state.barnsList = payload.map(item => item.id);
};

export const setFenceFeedHeights = (state: RootState, payload: { fenceId: number, feedHeights: EstimatedFeedHeight[] }): void => {
    Vue.set(state.fencesFeedHeights, payload.fenceId, payload.feedHeights);
};

export const setFeedTypes = (state: RootState, payload: FeedType[]): void => {
    state.feedTypes = createRecordByIdFromArray(payload);
    state.feedTypesList = payload.map(item => item.id);
};

export const setRations = (state: RootState, payload: Ration[]): void => {
    state.rations = payload;
};

export const updateRation = (state: RootState, payload: Ration): void => {
    if (payload.id && state.rations.map(r => r.id).includes(payload.id)) {
        Vue.set(state.rations, state.rations.findIndex(r => r.id === payload.id), payload);
    } else {
        state.rations.push(payload);
    }
};

export const removeRation = (state: RootState, payload: number): void => {
    state.rations = state.rations.filter(f => f.id !== payload);
};

export const setVehicles = (state: RootState, payload: Vehicle[]): void => {
    state.vehicles = createRecordByIdFromArray(payload);
    state.vehiclesList = payload.map(item => item.id);
};

export const setVehicleTypes = (state: RootState, payload: VehicleType[]): void => {
    state.vehicleTypes = createRecordByIdFromArray(payload);
    state.vehicleTypesList = payload.map(item => item.id);
};

export const setShowDebug = (state: RootState, payload: boolean): void => {
    state.showDebug = payload;
};

export const setLoading = (state: RootState, isLoading: boolean): void => {
    if (isLoading) {
        state.loadingRefCount += 1;
    } else if (state.loadingRefCount > 0) {
        state.loadingRefCount -= 1;
    }
};

export const setLocales = (state: RootState, payload: Record<string, string>): void => {
    state.locales = payload;
};

export const setActions = (state: RootState, actions: VehicleAction[]): void => {
    state.actions = actions;
};

export const setVehicleTypeActions = (state: RootState, payload: { vehicleTypeId: number, actions: VehicleAction[] }): void => {
    Vue.set(state.vehicleTypeActions, payload.vehicleTypeId, payload.actions);
};

export const setPresetRuns = (state: RootState, payload: PresetRun[]): void => {
    state.presetRuns = payload;
};

export const setVehicleStatusses = (state: RootState, payload: VehicleStatus[]): void => {
    state.vehicleStatusses = payload;
};

export const setPlanningSettings = (state: RootState, payload: PlanningSettings): void => {
    state.planningSettings = payload;
};

export const updatePresetRun = (state: RootState, payload: PresetRun): void => {
    if (payload.id && state.presetRuns.map(pr => pr.id).includes(payload.id)) {
        Vue.set(state.presetRuns, state.presetRuns.findIndex(f => f.id === payload.id), payload);
    } else {
        state.presetRuns.push(payload);
    }
};

export const removePresetRun = (state: RootState, payload: number): void => {
    state.presetRuns = state.presetRuns.filter(f => f.id !== payload);
};

export const setPlanningInProgress = (state: RootState, payload: boolean): void => {
    state.planningInProgress = payload;
};

export const triggerPlanningUpdate = (state: RootState): void => {
    state.planningTriggerDate = new Date();
};

export const updateBarn = (state: RootState, payload: Barn): void => {
    Vue.set(state.barns, payload.id, payload);
};

export const setEditRation = (state: RootState, payload: Ration): void => {
    state.editRation = payload;
};

export const setEditRationFenceIds = (state: RootState, payload: number[]): void => {
    state.editRationFenceIds = payload;
};

export const setEditRationFeedTypes = (state: RootState, payload: FeedType[]): void => {
    state.editRationFeedTypes = payload;
};
export const setEditRationDirty = (state: RootState, payload: boolean): void => {
    state.editRationDirty = payload;
};

export const setFarmPlanners = (state: RootState, payload: FarmPlanner[]): void => {
    state.farmPlanners = payload;
};

export const setUnavailabilities = (state: RootState, payload: Unavailability[]): void => {
    state.unavailabilities = payload;
};

export const setRunsFilterPreferences = (state: RootState, payload: RunsFilterPreferences): void => {
    state.runsFilterPreferences = payload;
};
