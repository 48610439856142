
import { Component, Prop, Watch } from 'vue-property-decorator';
import Unavailability from '@/core/interfaces/Unavailability';
import { addMinutes, format, startOfToday } from 'date-fns';
import VehicleType from '@/core/interfaces/VehicleType';
import { Getter } from 'vuex-class';
import VehicleTypeColorService from '@/core/VehicleTypeColorService';
import { mixins } from 'vue-class-component';
import UsesVehicleTypeActionIcon from '@/components/shared/mixins/UsesVehicleTypeActionIcon';
import UsesWeekdays from '@/components/shared/mixins/UsesWeekdays';
import UsesCanvasRectangles, { CanvasRectangle } from '@/components/shared/mixins/UsesCanvasRectangles';
import VehicleAction from '@/core/interfaces/VehicleAction';
import PauzesPopover from '@/components/shared/Pauzes/PauzesPopover.vue';
import UsesCanvasPeriod from '@/components/shared/mixins/UsesCanvasPeriod';

@Component({
    components: {
        PauzesPopover,
    },
})
export default class Pauzes
    extends mixins<UsesCanvasPeriod,
        UsesCanvasRectangles<Unavailability>,
        UsesVehicleTypeActionIcon,
        UsesWeekdays>(UsesCanvasPeriod, UsesCanvasRectangles, UsesVehicleTypeActionIcon, UsesWeekdays) {
    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Getter('actionById')
    actionById!: (id: number) => VehicleAction;

    @Prop({ required: true })
    unavailabilities!: Unavailability[];

    @Prop({ default: true })
    showVehicleType!: boolean;

    @Prop()
    vehicleType!: VehicleType;

    minDayHeight = 70;

    rectMargin = 5;

    rectHeight = 26;

    rectStrokeStyle = 'rgba(195, 10, 20, .5)';

    rectStrokeStyleDisabled = 'rgba(195, 10, 20, .2)';

    rectFillStyle = 'rgba(255, 226, 226, 1)';

    rectFillStyleDisabled = 'rgba(255, 226, 226, .3)';

    rectTextFillStyle = 'rgba(255, 255, 255, .4)';

    horizontalGridStyle = 'rgba(0, 0, 0, .4)';

    verticalGridStyle = 'rgba(0, 0, 0, .05)';

    nrOfTimeGridLines = 25;

    iconSize = 20;

    imageCache: Record<string, HTMLImageElement> = {};

    @Watch('unavailabilities')
    async draw(): Promise<void> {
        await this.loadImages();

        this.clearHoveringRectangles();
        this.clearCanvas();
        this.drawWeekdays();
        this.drawTimes();
        this.drawUnavailabilities();
    }

    drawWeekdays(): void {
        const ctx = this.context;

        this.weekdayNumbers.forEach((weekday: number, index: number) => {
            ctx.font = '14px Ubuntu';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'right';
            ctx.textBaseline = 'middle';
            ctx.fillText(this.weekdayNames[weekday], this.leftMargin - 20, this.dayHeightCenters[weekday]);

            const height = this.dayHeightTops[weekday] + 0.5;
            if (index > 0) {
                ctx.lineWidth = 1;
                ctx.strokeStyle = this.horizontalGridStyle;
                ctx.beginPath();
                ctx.moveTo(this.leftMargin, height);
                ctx.lineTo(this.graphWidth + this.leftMargin, height);
                ctx.stroke();
                ctx.closePath();
            }
        });
    }

    drawTimes(): void {
        const minutesInDay = (60 * 24);
        const minutePerGridLine = minutesInDay / (this.nrOfTimeGridLines - 1);
        const start = startOfToday();
        const ctx = this.context;

        return [...Array(this.nrOfTimeGridLines)
            .keys()]
            .map(index => index * minutePerGridLine)
            .forEach(minutes => {
                const date = addMinutes(start, minutes);
                const x = this.leftMargin + this.minutesPerXPixel * minutes + 0.5;
                const time = format(date, 'HH');

                ctx.lineWidth = 1;
                ctx.strokeStyle = this.verticalGridStyle;
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, this.graphHeight);
                ctx.stroke();
                ctx.closePath();

                ctx.font = '14px Ubuntu';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                ctx.fillText(time, x, this.graphHeight + 10);
            });
    }

    drawUnavailabilities(): void {
        const ctx = this.context;

        const iconFont = '11px icons';

        this.unavailabilities.forEach(unavailability => {
            const vehicleType = this.getVehicleType(unavailability);
            const text = vehicleType.name;
            const rect = this.unavailabilityRectangles[unavailability.id];

            rect.forEach(rect => {
                const textLeftPadding = 10;
                const iconPadding = 5;
                const textTop = rect.y + (rect.h / 2);
                const nrOfIcons = this.getActions(unavailability).length;
                const measuredTextWidth = ctx.measureText(text).width;
                const textRectWidth = textLeftPadding + measuredTextWidth + textLeftPadding + (nrOfIcons * (this.iconSize + iconPadding)) + textLeftPadding;
                const textRectWidthWithoutIcons = textLeftPadding + measuredTextWidth + textLeftPadding;

                this.registerHoverRectangle({
                    x: rect.x,
                    y: rect.y,
                    w: rect.w,
                    h: rect.h,
                }, unavailability, true, 'tooltip');

                // bg
                ctx.fillStyle = unavailability.enabled ? this.rectFillStyle : this.rectFillStyleDisabled;
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h);

                if (textRectWidth <= rect.w) {
                    const textLeft = rect.x + (rect.w / 2) - (textRectWidth / 2);
                    const iconStartLeft = textLeft + textLeftPadding + measuredTextWidth + textLeftPadding;
                    // text + icons
                    ctx.fillStyle = this.rectTextFillStyle;
                    ctx.fillRect(textLeft, rect.y, textRectWidth, rect.h);

                    ctx.fillStyle = unavailability.enabled ? VehicleTypeColorService.get(vehicleType).stroke : VehicleTypeColorService.get(vehicleType).fill;
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'middle';
                    ctx.font = 'bold 14px Ubuntu';
                    ctx.fillText(text, textLeft + textLeftPadding, textTop, textRectWidth);

                    this.getActions(unavailability).forEach((action, index) => {
                        const key = this.getImageCacheKey(this.getVehicleType(unavailability).id, action);
                        const icon = this.imageCache[key];
                        if (icon) {
                            ctx.drawImage(icon, iconStartLeft + (index * (this.iconSize + iconPadding)), textTop - (this.iconSize / 2));
                        }
                    });
                } else if (textRectWidthWithoutIcons <= rect.w) {
                    const textLeft = rect.x + (rect.w / 2) - (textRectWidthWithoutIcons / 2);
                    // text + icons
                    ctx.fillStyle = this.rectTextFillStyle;
                    ctx.fillRect(textLeft, rect.y, textRectWidthWithoutIcons, rect.h);

                    ctx.fillStyle = VehicleTypeColorService.get(vehicleType).stroke;
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'middle';
                    ctx.font = 'bold 14px Ubuntu';
                    ctx.fillText(text, textLeft + textLeftPadding, textTop, textRectWidthWithoutIcons - (textLeftPadding * 2));
                }

                // Star icon
                ctx.fillStyle = 'rgba(0, 0, 0, 1)';
                ctx.font = iconFont;

                // CanvasRectangle
                ctx.beginPath();
                ctx.strokeStyle = unavailability.enabled ? this.rectStrokeStyle : this.rectStrokeStyleDisabled;
                ctx.rect(rect.x, rect.y, rect.w, rect.h);
                ctx.stroke();
            });
        });
    }

    get dayHeights(): Record<number, number> {
        return this.weekdayNumbers.reduce((acc: Record<number, number>, weekday: number) => {
            acc[weekday] = Math.max(
                this.minDayHeight,
                this.maxOverlappingUnavailabilitiesPerDay[weekday] * (this.rectHeight + this.rectMargin) + this.rectMargin,
            );

            return acc;
        }, {});
    }

    get dayHeightTops(): Record<number, number> {
        return this.weekdayNumbers.reduce((acc: Record<number, number>, weekday: number, index: number) => {
            if (index === 0) {
                acc[weekday] = 0;
                return acc;
            }

            const lastHeight = Object.values(acc)[index - 1];
            const prevDayIndex = this.weekdayNumbers.indexOf(weekday);
            const prevDayHeight = this.dayHeights[prevDayIndex];

            acc[weekday] = lastHeight + prevDayHeight;
            return acc;
        }, {});
    }

    get dayHeightCenters(): Record<number, number> {
        return Object.entries(this.dayHeightTops)
            .reduce((acc: Record<number, number>, [weekday, top]) => {
                acc[Number(weekday)] = top + (this.dayHeights[Number(weekday)] / 2);

                return acc;
            }, {});
    }

    get unavailabilityRectangles(): Record<number, CanvasRectangle[]> {
        return Object.entries(this.overlappingUnavailabilitiesGroupsPerDay)
            .reduce((acc: Record<number, CanvasRectangle[]>, group) => {
                const [weekday, groups] = group;
                groups.forEach(group => {
                    group.forEach((unavailability, index) => {
                        if (acc.hasOwnProperty(unavailability.id)) {
                            acc[unavailability.id].push(this.calculateUnavailabilityRect(Number(weekday), unavailability, group.length, index));
                        } else {
                            acc[unavailability.id] = [this.calculateUnavailabilityRect(Number(weekday), unavailability, group.length, index)];
                        }
                    });
                });

                return acc;
            }, {});
    }

    get graphHeight(): number {
        return Object.values(this.dayHeights)
            .reduce((acc, value) => acc + value);
    }

    get calculatedCanvasHeight(): number {
        return this.graphHeight + 50;
    }

    get leftMargin(): number {
        return 100;
    }

    calculateUnavailabilityRect(weekday: number, unavailability: Unavailability, groupLength: number, groupIndex: number): CanvasRectangle {
        let y;
        if (groupLength === 1) {
            y = this.dayHeightCenters[weekday] - (this.rectHeight / 2);
        } else {
            y = this.dayHeightTops[weekday] + (groupIndex * (this.rectHeight + this.rectMargin)) + this.rectMargin;
        }

        y += 0.5;

        const x = this.getXPixelForTime(unavailability.time_from) + 0.5;
        const x2 = this.getXPixelForTime(unavailability.time_to) + 0.5;

        return {
            x,
            y,
            w: x2 - x,
            h: this.rectHeight,
        };
    }

    get unavailabilitiesPerDay(): Record<number, Unavailability[]> {
        return this.weekdayNumbers.reduce((acc: Record<number, Unavailability[]>, weekday: number) => {
            acc[weekday] = this.unavailabilities
                .filter(unavailability => unavailability.weekdays.includes(weekday));
            return acc;
        }, {});
    }

    get maxOverlappingUnavailabilitiesPerDay(): Record<number, number> {
        return this.weekdayNumbers.reduce((acc: Record<number, number>, weekday: number) => {
            acc[weekday] = Math.max(0, ...this.overlappingUnavailabilitiesGroupsPerDay[weekday].map(day => day.length));

            return acc;
        }, {});
    }

    unavailabilityOverlaps(unavailability1: Unavailability, unavailability2: Unavailability): boolean {
        return unavailability1.time_from <= unavailability2.time_to && unavailability1.time_to >= unavailability2.time_from;
    }

    get overlappingUnavailabilitiesGroupsPerDay(): Record<number, Unavailability[][]> {
        return Object
            .entries(this.unavailabilitiesPerDay)
            .reduce((acc: Record<number, Unavailability[][]>, [weekday, unavailabilities]) => {
                acc[Number(weekday)] = this.calculateUnavailabilityOverlapGroupsPerDay(unavailabilities);

                return acc;
            }, {});
    }

    calculateUnavailabilityOverlapGroupsPerDay(unavailabilities: Unavailability[]): Unavailability[][] {
        const overlapped: Unavailability[] = [];

        return unavailabilities.map(unavailability => {
            if (overlapped.includes(unavailability)) {
                return [];
            }
            const overlapping = this.getRecursiveOverlapping(unavailability, unavailabilities
                .filter(overlappedUnavailability => !overlapped.includes(overlappedUnavailability)));

            overlapped.push(...overlapping);

            return Object.values([...overlapping, unavailability]
                .reduce((acc: Record<number, Unavailability>, unavailability) => {
                    acc[unavailability.id] = unavailability;

                    return acc;
                }, {}))
                .sort((a, b) => Number(a.time_from.replace(':', ''))
                    - Number(b.time_from.replace(':', '')));
        })
            .filter(group => group.length);
    }

    getRecursiveOverlapping(unavailability: Unavailability, unavailabilities: Unavailability[]): Unavailability[] {
        const otherUnavailabilities = unavailabilities.filter(otherUnavailability => unavailability.id !== otherUnavailability.id);
        const overlappedUnavailabilities = otherUnavailabilities
            .filter(otherUnavailability => this.unavailabilityOverlaps(unavailability, otherUnavailability));

        const leftoverUnavailabilities = otherUnavailabilities
            .filter(leftoverUnavailability => !overlappedUnavailabilities.includes(leftoverUnavailability));

        const recursiveResult = overlappedUnavailabilities
            .map(overlappedUnavailability => this.getRecursiveOverlapping(overlappedUnavailability, leftoverUnavailabilities))
            .flat();

        return [
            ...overlappedUnavailabilities,
            ...recursiveResult,
        ];
    }

    async loadImages(): Promise<void> {
        const uniqueImages: Record<string, HTMLImageElement> = {};

        this.unavailabilities.forEach(unavailability => {
            this.getActions(unavailability).forEach(action => {
                const key = this.getImageCacheKey(this.getVehicleType(unavailability).id, action);

                if (!uniqueImages.hasOwnProperty(key)) {
                    uniqueImages[key] = this.createVehicleTypeActionIcon(this.getVehicleType(unavailability), action, unavailability.enabled, this.iconSize);
                }
            });
        });

        await Promise.all(
            Object.entries(uniqueImages)
                .map(([key, image]) => new Promise<void>((resolve) => {
                    if (image.complete) {
                        this.imageCache[key] = image;
                        resolve();
                    }

                    image.addEventListener('load', () => {
                        this.imageCache[key] = image;
                        resolve();
                    });
                })),
        );
    }

    getImageCacheKey(vehicleTypeId: number, action: VehicleAction): string {
        return `${vehicleTypeId}-${action.name}`;
    }

    handleClick(event: MouseEvent): void {
        const rect = this.getHoveringRectangle(event.clientX, event.clientY);

        if (!rect) {
            return;
        }

        this.$emit('edit', rect.value);
    }

    getActions(unailability: Unavailability): VehicleAction[] {
        return unailability.actions.map(action => this.actionById(action));
    }

    getVehicleType(unailability: Unavailability): VehicleType {
        if (unailability.vehicle_type_id) {
            return this.vehicleTypeById(unailability.vehicle_type_id);
        }

        return this.vehicleType;
    }
}

