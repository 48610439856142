
import { Component, Prop, Vue } from 'vue-property-decorator';
import Run from '@/core/interfaces/Run';
import { Getter } from 'vuex-class';
import Fence from '@/core/interfaces/Fence';
import VehicleTypeActionIcon from '@/components/shared/VehicleTypeActionIcon.vue';
import VehicleType from '@/core/interfaces/VehicleType';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleAction from '@/core/interfaces/VehicleAction';
import FenceVisitAction from '@/core/enums/FenceVisitAction';
import { sumArray } from '@/helpers';

@Component({
    computed: {
        FenceVisitAction() {
            return FenceVisitAction;
        },
    },
    components: { VehicleTypeActionIcon },
})
export default class DashboardVehiclesStatusRunTooltip extends Vue {
    @Prop()
    run!: Run;

    @Getter('fenceById')
    fenceById!: (id: number) => Fence;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Getter('vehicleById')
    vehicleById!: (id: number) => Vehicle;

    @Getter('actionById')
    actionById!: (id: number) => VehicleAction;

    get vehicleType(): VehicleType {
        return this.vehicleTypeById(this.vehicleById(this.run.vehicle_id).vehicle_type_id);
    }

    get feedActionId(): number {
        return this.$store.getters.actionByName(FenceVisitAction.FEED)?.id;
    }

    get fenceVisitKgs(): number[] {
        return this.run.fence_visits.map((fenceVisit) => sumArray(fenceVisit.feed_visits
            .map((feedVisit) => feedVisit.requested_kg)));
    }
}

