import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import i18n from '@/plugins/i18n';

Vue.use(BootstrapVue, {
    BModal: {
        okTitle: i18n.t('general.ok'),
        cancelTitle: i18n.t('general.cancel'),
        cancelVariant: 'link',
    },
});
