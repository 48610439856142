import { Module } from 'vuex';
import actions from '@/store/modules/atj/actions';
import mutations from '@/store/modules/atj/mutations';
import { RootState } from '@/store/rootState';
import state from '@/store/modules/atj/state';
import getters from '@/store/modules/atj/getters';
import { AtjState } from '@/store/modules/atj/atjState';

const atj: Module<AtjState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default atj;
