
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { LelyUserDefinition } from '@tec/frontend-vue-shared';

@Component({})
export default class Login extends Vue {
    async login(user: LelyUserDefinition): Promise<void> {
        this.$store.commit('setUserId', user.id);
        await store.dispatch('syncFarms');

        const farmIdFromLocalStorage = window.localStorage.getItem('lely_hmf_farm_id');
        if (farmIdFromLocalStorage && Object.keys(store.state.farms).includes(farmIdFromLocalStorage)) {
            await this.selectFarm(Number(farmIdFromLocalStorage));
        } else if (Object.keys(store.state.farms).length) {
            const firstFarmId = Object.keys(store.state.farms)[0];
            await this.selectFarm(Number(firstFarmId));
        }

        await this.$router.push({
            name: 'dashboard.overview',
        });
    }

    private async selectFarm(farmId: number): Promise<void> {
        window.localStorage.setItem('lely_hmf_farm_id', String(farmId));
        store.commit('setFarmId', farmId);
        await store.dispatch('syncFarm', farmId);
    }
}

