import { MutationTree } from 'vuex';
import { KpisState } from '@/store/modules/kpis/kpisState';
import VehicleKpiDate from '@/core/interfaces/VehicleKpiDate';
import VehicleStateDurationCategory from '@/core/interfaces/VehicleStateDurationCategory';

const mutations: MutationTree<KpisState> = {
    setVehicleKpiDates: (state: KpisState, payload: VehicleKpiDate[]) => {
        state.vehicleKpiDates = payload;
    },

    setVehicleStateDurationCategories: (state: KpisState, payload: VehicleStateDurationCategory[]) => {
        state.vehicleStateDurationCategories = payload;
    },
};

export default mutations;
