import { Module } from 'vuex';
import actions from '@/store/modules/notifications/actions';
import mutations from '@/store/modules/notifications/mutations';
import { RootState } from '@/store/rootState';
import { NotificationState } from '@/store/modules/notifications/notificationState';
import state from '@/store/modules/notifications/state';

const notifications: Module<NotificationState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default notifications;
