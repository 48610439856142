
import { Component, Prop, Vue } from 'vue-property-decorator';
import EnabledModules from '@/core/interfaces/EnabledModules';
import { State } from 'vuex-class';

@Component({})
export default class LelyNavigationModuleSwitcher extends Vue {
    @Prop()
    activeModule!: string;

    @State('enabledModules')
    enabledModules!: EnabledModules;
}

