import { Module } from 'vuex';
import actions from '@/store/modules/aurora/actions';
import mutations from '@/store/modules/aurora/mutations';
import { RootState } from '@/store/rootState';
import state from '@/store/modules/aurora/state';
import getters from '@/store/modules/aurora/getters';
import { AuroraState } from '@/store/modules/aurora/auroraState';

const aurora: Module<AuroraState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default aurora;
