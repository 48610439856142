import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { AfxState } from '@/store/modules/afx/afxState';
import { AfxBarnZone } from '@/core/interfaces/AfxBarnZone';
import { AfxBarnZoneScheduledBlock } from '@/core/interfaces/AfxBarnZoneScheduledBlock';

const getters: GetterTree<AfxState, RootState> = {
    afxApiUrl: (state: AfxState, getters: any, rootState: RootState) => (url: string): string => `/afx/farms/${rootState.farmId}${url}`,

    barnZones: (state: AfxState) => state.barnZones.sort((a, b) => a.name.localeCompare(b.name)),

    barnZoneScheduledBlocks: (state: AfxState) => state.barnZoneScheduledBlocks,

    barnZoneById: (state: AfxState) => (barnZoneId: number): AfxBarnZone | null => state.barnZones.find(zone => zone.id === barnZoneId) || null,

    barnZoneScheduledBlocksByBarnZone: (state: AfxState) => (barnZoneId: number): AfxBarnZoneScheduledBlock[] => state
        .barnZoneScheduledBlocks.filter(block => block.barn_zone_id === barnZoneId),

    barnZoneManualBlockedUntil: (state: AfxState) => (barnZoneId: number): string | null => state
        .barnZonesManualBlocked.find(block => block.barnZoneId === barnZoneId)?.until || null,

};
export default getters;
