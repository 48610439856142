import { Module } from 'vuex';
import actions from '@/store/modules/exos/actions';
import mutations from '@/store/modules/exos/mutations';
import { RootState } from '@/store/rootState';
import { ExosState } from '@/store/modules/exos/exosState';
import state from '@/store/modules/exos/state';
import getters from '@/store/modules/exos/getters';

const exos: Module<ExosState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default exos;
