
import { Component, Prop } from 'vue-property-decorator';
import Unavailability from '@/core/interfaces/Unavailability';
import { Getter } from 'vuex-class';
import VehicleType from '@/core/interfaces/VehicleType';
import { mixins } from 'vue-class-component';
import UsesWeekdays from '@/components/shared/mixins/UsesWeekdays';
import UsesVehicleTypeActionIcon from '@/components/shared/mixins/UsesVehicleTypeActionIcon';
import VehicleAction from '@/core/interfaces/VehicleAction';
import Fence from '@/core/interfaces/Fence';

@Component({})
export default class PauzesPopover extends mixins(UsesWeekdays, UsesVehicleTypeActionIcon) {
    @Prop({ required: true })
    unavailability!: Unavailability;

    @Prop({ default: true })
    showVehicleType!: boolean;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Getter('fenceById')
    fenceById!: (id: number) => Fence;

    @Getter('actionById')
    actionById!: (id: number) => VehicleAction;

    imgHeight = 20;

    get actions(): { image: string|null, action: string }[] {
        return this.unavailability.actions.map(actionId => ({
            image: this.vehicleType ? this.getVehicleTypeActionIconObjectURL(this.vehicleType, this.actionById(actionId), true, this.imgHeight) : null,
            action: this.actionById(actionId).name,
        }));
    }

    get fences(): Fence[] {
        return this.unavailability.fences.map(fenceId => this.fenceById(fenceId)).sort((a, b) => a.name.localeCompare(b.name));
    }

    get vehicleType(): VehicleType | null {
        return this.vehicleTypeById(this.unavailability.vehicle_type_id) || null;
    }
}

