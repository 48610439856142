
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class YearSelector extends Vue {
    @Prop({ required: true })
    value!: number;

    @Prop()
    years!: number[];

    get yearsSorted(): number[] {
        return this.years.sort();
    }
}
