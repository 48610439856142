import { MutationTree } from 'vuex';
import { AuroraState } from '@/store/modules/aurora/auroraState';
import AuroraStitchedImageResponse from '@/core/interfaces/AuroraStitchedImageResponse';
import { AuroraImageResponse } from '@/core/interfaces/AuroraImageResponse';

const mutations: MutationTree<AuroraState> = {
    setHubZones: (state, hubZones) => {
        state.hubZones = hubZones;
    },

    setStitchedImageResponse: (state, response: AuroraStitchedImageResponse) => {
        state.stitchedImageResponse = response;
    },

    setImageAreaPreviewResponse: (state, response: AuroraImageResponse) => {
        state.imageAreaPreviewResponse = response;
    },
};

export default mutations;
