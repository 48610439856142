import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { AtjState } from '@/store/modules/atj/atjState';
import SweepPlanning from '@/components/modules/atj/core/types/SweepPlanning';

let updateSweepPlanningAbortController: AbortController | null;

const actions: ActionTree<AtjState, RootState> = {
    fetchSweepPlanning: async(context: ActionContext<AtjState, RootState>) => axios.get(context.getters.atjApiUrl('/sweep-planning'))
        .then(response => context.commit('setSweepPlanning', response.data)),

    removeSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: SweepPlanning) => {
        context.commit('removeSweepPlanning', payload);

        axios
            .delete(context.getters.atjApiUrl(`/sweep-planning/${payload.id}`))
            .then(() => context.dispatch('fetchSweepPlanning'));
    },

    addSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: Partial<SweepPlanning>) => {
        context.commit('addSweepPlanning', payload);

        axios
            .post(context.getters.atjApiUrl('/sweep-planning'), payload)
            .then(() => context.dispatch('fetchSweepPlanning'));
    },

    updateSweepPlanning: async(context: ActionContext<AtjState, RootState>, payload: SweepPlanning) => {
        context.commit('updateSweepPlanning', payload);

        if (updateSweepPlanningAbortController) {
            updateSweepPlanningAbortController.abort();
        }

        updateSweepPlanningAbortController = new AbortController();

        axios
            .put(context.getters.atjApiUrl(`/sweep-planning/${payload.id}`), payload, {
                signal: updateSweepPlanningAbortController.signal,
            })
            .then(() => context.dispatch('fetchSweepPlanning'));
    },
};

export default actions;
