import { MutationTree } from 'vuex';
import { AtjState } from '@/store/modules/atj/atjState';
import Vue from 'vue';
import SweepPlanning from '@/components/modules/atj/core/types/SweepPlanning';

const mutations: MutationTree<AtjState> = {
    setSweepPlanning: (state: AtjState, payload: SweepPlanning[]) => {
        state.sweepPlanning = payload;
    },
    updateSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        const index = state.sweepPlanning.findIndex(sweepPlanning => sweepPlanning.id === payload.id);

        Vue.set(state.sweepPlanning, index, payload);
    },
    addSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        state.sweepPlanning.push(payload);
    },
    removeSweepPlanning: (state: AtjState, payload: SweepPlanning) => {
        const index = state.sweepPlanning.findIndex(sweepPlanning => sweepPlanning.id === payload.id);

        Vue.delete(state.sweepPlanning, index);
    },
};

export default mutations;
