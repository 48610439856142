
import { Component, Prop, Vue } from 'vue-property-decorator';
import Breadcrumbs, { LelyBreadcrumb } from '@/components/shared/Breadcrumbs.vue';
import FormButtons from '@/components/shared/FormButtons.vue';

@Component({
    components: {
        FormButtons,
        Breadcrumbs,
    },
})
export default class PageHeader extends Vue {
    @Prop({})
    breadcrumbs!: LelyBreadcrumb[];

    @Prop({})
    title!: string;

    @Prop({ type: Boolean, default: false })
    sticky!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    formButtons!: boolean;

    @Prop({ default: false })
    formLoading!: boolean;

    mounted(): void {
        const observer = new IntersectionObserver(
            ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
            { threshold: [1], rootMargin: '-61px 0px 0px 0px' },
        );

        observer.observe(this.$el);
    }

    get computedBreadcrumbs(): LelyBreadcrumb[] {
        if (this.title) {
            return [
                {
                    label: this.title,
                },
            ];
        }

        return this.breadcrumbs;
    }
}
