import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { AfxState } from '@/store/modules/afx/afxState';
import axios from 'axios';
import { AfxBarnZoneScheduledBlock } from '@/core/interfaces/AfxBarnZoneScheduledBlock';

const actions: ActionTree<AfxState, RootState> = {
    fetchBarnZones: async(context: ActionContext<AfxState, RootState>) => axios.get(context.getters.afxApiUrl('/barn-zones'))
        .then(response => context.commit('setBarnZones', response.data)),

    fetchBarnZoneScheduledBlocks: async(context: ActionContext<AfxState, RootState>) => axios.get(context.getters.afxApiUrl('/barn-zone-scheduled-blocks'))
        .then(response => context.commit('setBarnZoneScheduledBlocks', response.data)),

    fetchBarnZoneManualBlocked: async(
        context: ActionContext<AfxState, RootState>,
        payload: number,
    ) => axios.get(context.getters.afxApiUrl(`/barn-zones/${payload}/manual-block`))
        .then(response => context.commit('setBarnZoneManualBlocked', {
            barnZoneId: payload,
            until: response.data.block_until,
        })),

    manualBlockBarnZone: async(
        context: ActionContext<AfxState, RootState>,
        payload: {id: number, until: string | null},
    ) => axios.put(context.getters.afxApiUrl(`/barn-zones/${payload.id}/manual-block`), {
        id: payload.id,
        block_until: payload.until,
    }).then(response => context.commit('setBarnZoneManualBlocked', {
        barnZoneId: payload.id,
        until: response.data.block_until,
    })),

    saveBarnZoneScheduledBlock: async(
        context: ActionContext<AfxState, RootState>,
        barnZoneScheduledBlock: Partial<AfxBarnZoneScheduledBlock>,
    ): Promise<AfxBarnZoneScheduledBlock> => {
        let res;

        if (barnZoneScheduledBlock.id) {
            res = await axios.put(context.getters.afxApiUrl(`/barn-zone-scheduled-blocks/${barnZoneScheduledBlock.id}`, barnZoneScheduledBlock),
                barnZoneScheduledBlock);
        } else {
            res = await axios.post(context.getters.afxApiUrl('/barn-zone-scheduled-blocks', barnZoneScheduledBlock), barnZoneScheduledBlock);
        }

        return res.data;
    },

    deleteBarnZoneScheduledBlock: async(context: ActionContext<AfxState, RootState>, payload: number) => {
        await axios.delete(context.getters.afxApiUrl(`/barn-zone-scheduled-blocks/${payload}`));
        await context.dispatch('fetchBarnZoneScheduledBlocks');
    },
};

export default actions;
