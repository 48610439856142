import { ActionTree } from 'vuex';
import { NotificationState } from '@/store/modules/notifications/notificationState';
import { RootState } from '@/store/rootState';
import Notification from '@/core/interfaces/Notification';

let notificationIndex = 0;

const actions: ActionTree<NotificationState, RootState> = {
    add(state, payload: { message: string, type?: 'error' | 'warning' | 'info' }) {
        state.commit('add', {
            id: notificationIndex += 1,
            message: payload.message,
            type: payload.type || 'info',
        });
    },
    remove(state, notification: Notification) {
        state.commit('remove', notification);
    },
    clear(state) {
        state.commit('clear');
    },

};

export default actions;
