
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FormButtons extends Vue {
    @Prop({ default: false })
    formLoading!: boolean;

    @Prop({ default: false })
    saveDisabled!: boolean;

    @Prop({ default: false })
    resetDisabled!: boolean;
}
