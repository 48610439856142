
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Barn from '@/core/interfaces/Barn';
import Fence from '@/core/interfaces/Fence';

@Component({})
export default class OverviewsPage extends Vue {
    @Getter('barnsSet')
    barns!: Barn[];

    @Getter('fencesByBarn')
    fencesByBarn!: (id: number) => Fence[];
}
