import { ExosState } from '@/store/modules/exos/exosState';

export default {
    activeRuns: [],
    birdNests: [],
    birds: {},
    birdsList: [],
    fields: {},
    fieldsList: [],
    kniveSets: [],
    planning: {},
    planningList: [],
    mowplans: {},
    mowplansList: [],
    fertilizerTanks: [],
} as ExosState;
