import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class UsesCanvas extends Vue {
    width = 0;

    height = 0;

    get canvas(): HTMLCanvasElement {
        return this.$refs.canvas as HTMLCanvasElement;
    }

    get context(): CanvasRenderingContext2D {
        return this.canvas.getContext('2d') as CanvasRenderingContext2D;
    }

    get container(): HTMLElement {
        return this.$refs.container as HTMLElement;
    }

    get calculatedCanvasHeight(): number {
        return 100;
    }

    draw(): void {
        // noop
    }

    clearCanvas(): void {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    mounted(): void {
        this.updateSize();
        this.draw();

        window.addEventListener('resize', () => {
            setTimeout(() => {
                this.updateSize();
                this.draw();
            }, 0);
        });
    }

    @Watch('calculatedCanvasHeight')
    updateSize(): void {
        const ratio = window.devicePixelRatio;
        const rect = this.container.getBoundingClientRect();

        const height = this.calculatedCanvasHeight;

        this.canvas.width = rect.width * ratio;
        this.canvas.height = height * ratio;

        this.canvas.style.height = `${height}px`;
        this.canvas.style.width = `${rect.width}px`;

        this.width = rect.width;
        this.height = height;

        this.context.scale(ratio, ratio);
    }

    /**
     * Draws a rounded rectangle using the current state of the canvas.
     * If you omit the last three params, it will draw a rectangle
     * outline with a 5 pixel border radius
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number} x The top left x coordinate
     * @param {Number} y The top left y coordinate
     * @param {Number} width The width of the rectangle
     * @param {Number} height The height of the rectangle
     * @param {Number} [radius = 5] The corner radius; It can also be an object
     *                 to specify different radii for corners
     * @param {Number} [radius.tl = 0] Top left
     * @param {Number} [radius.tr = 0] Top right
     * @param {Number} [radius.br = 0] Bottom right
     * @param {Number} [radius.bl = 0] Bottom left
     * @param {Boolean} [fill = false] Whether to fill the rectangle.
     * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
     */
    drawRoundRec(
        x: number,
        y: number,
        width: number,
        height: number,
        radius: number |
            {
                tl?: number,
                tr?: number,
                br?: number,
                bl?: number
            } = 3,
        fill = false,
        stroke = false,
    ): void {
        if (!this.context) {
            return;
        }

        const ctx = this.context;
        let radiusObj = {
            tl: 0,
            tr: 0,
            br: 0,
            bl: 0,
        };

        if (typeof radius === 'number') {
            radiusObj = {
                tl: radius,
                tr: radius,
                br: radius,
                bl: radius,
            };
        } else {
            const defaultRadius = {
                tl: 0,
                tr: 0,
                br: 0,
                bl: 0,
            };

            Object.keys(defaultRadius)
                .forEach(side => {
                    // @ts-ignore
                    radiusObj[side] = radius[side] || defaultRadius[side];
                });
        }
        ctx.beginPath();
        ctx.moveTo(x + radiusObj.tl, y);
        ctx.lineTo(x + width - radiusObj.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radiusObj.tr);
        ctx.lineTo(x + width, y + height - radiusObj.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radiusObj.br, y + height);
        ctx.lineTo(x + radiusObj.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radiusObj.bl);
        ctx.lineTo(x, y + radiusObj.tl);
        ctx.quadraticCurveTo(x, y, x + radiusObj.tl, y);
        ctx.closePath();
        if (fill) {
            ctx.fill();
        }
        if (stroke) {
            ctx.stroke();
        }
    }

    drawCircle(centerX: number, centerY: number, radius: number, fillStyle: string): void {
        this.context.beginPath();
        this.context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        this.context.fillStyle = fillStyle;
        this.context.fill();
    }
}
