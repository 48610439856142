import VehicleType from '@/core/interfaces/VehicleType';

export interface VehicleTypeColor {
    fill: string,
    stroke: string,
}

const colors: Record<string, VehicleTypeColor> = {
    exos: {
        fill: '#C1D89E',
        stroke: '#73bd07',
    },
    atj: {
        fill: '#8FB0CE',
        stroke: '#1F78B4',
    },
    mfr: {
        fill: '#FF9393',
        stroke: '#FD3535',
    },
    siematic: {
        fill: '#F7F6F4',
        stroke: '#766E68',
    },
};

const fallbackColor = {
    fill: '#ccc',
    stroke: '#333',
};

export default class VehicleTypeColorService {
    static get(vehicleType: VehicleType): VehicleTypeColor {
        return vehicleType && colors.hasOwnProperty(vehicleType.name) ? colors[vehicleType.name] : fallbackColor;
    }
}
