import {
    format, Interval, isSameDay, isToday, isYesterday,
} from 'date-fns';
import Vue from 'vue';
import DateHelper from '@/components/modules/exos/lib/DateHelper';
import i18n from '@/plugins/i18n';

Vue.filter('timeShort', (date: Date | string): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;

    if (isToday(dateObj)) {
        return format(dateObj, 'HH:mm');
    }

    return format(dateObj, 'dd/MM HH:mm');
});

Vue.filter('date', (date: Date | string, formatString = 'dd MMM yyyy'): string => DateHelper.formatDate(date, formatString));

Vue.filter('dateTimeShorthand', (date: Date | string, formatString = 'dd/MM HH:mm'): string => DateHelper
    .formatDateTimeOrShorthand(date, formatString));

Vue.filter('dateTime', (date: Date | string, formatString = 'dd MMM yyyy HH:mm'): string => DateHelper.formatDateTime(date, formatString));

Vue.filter('dateIntervalFormatted', (interval: Interval): string => {
    if (interval.start === null || interval.end === null) {
        return '-';
    }

    if (isSameDay(interval.start, interval.end)) {
        if (isToday(interval.start)) {
            return i18n.t('general.today').toString();
        }

        if (isYesterday(interval.start)) {
            return i18n.t('general.yesterday').toString();
        }

        return format(interval.start, 'dd MMM yyyy');
    }

    return `${format(interval.start, 'dd MMM yyyy')} - ${format(interval.end, 'dd MMM yyyy')}`;
});
