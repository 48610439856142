import { Component, Vue } from 'vue-property-decorator';
import VehicleTypeColorService, { VehicleTypeColor } from '@/core/VehicleTypeColorService';
import VehicleType from '@/core/interfaces/VehicleType';

@Component
export default class UsesVehicleTypeColors extends Vue {
    get vehicleTypeColors(): Record<number, VehicleTypeColor> {
        return this.$store.getters.vehicleTypesSet.reduce((acc: Record<number, VehicleTypeColor>, vehicleType: VehicleType) => {
            acc[vehicleType.id] = VehicleTypeColorService.get(vehicleType);

            return acc;
        }, {});
    }
}
