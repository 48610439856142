import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

@Component
export default class UsesBeforeWindowUnload extends Vue {
    created(): void {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
    }

    beforeDestroy(): void {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    }

    async confirmLeaveModal(): Promise<boolean> {
        return this.$bvModal.msgBoxConfirm(this.confirmLeaveText, {
            centered: true,
            size: 'sm',
            okTitle: this.$t('general.leave-confirm-changes-ok').toString(),
        });
    }

    confirmLeaveConfirm(): boolean {
        // eslint-disable-next-line no-alert
        return window.confirm(this.confirmLeaveText);
    }

    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
        await this.nextIfDirtyFormModal(next);
    }

    async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
        await this.nextIfDirtyFormModal(next);
    }

    private async nextIfDirtyFormModal(next: NavigationGuardNext): Promise<void> {
        if (this.formIsDirty && !(await this.confirmLeaveModal())) {
            next(false);
        } else {
            next();
        }
    }

    beforeWindowUnload(event: BeforeUnloadEvent): void {
        if (this.formIsDirty && !this.confirmLeaveConfirm()) {
            const eventDup = event;

            eventDup.preventDefault();
            eventDup.returnValue = '';
        }
    }

    get confirmLeaveText(): string {
        return this.$t('general.leave-confirm-changes').toString();
    }

    get formIsDirty(): boolean {
        return false;
    }
}
