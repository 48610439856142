import { Component, Vue } from 'vue-property-decorator';
import VehicleTypeActionIcon from '@/components/shared/VehicleTypeActionIcon.vue';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleAction from '@/core/interfaces/VehicleAction';

@Component
export default class UsesVehicleTypeActionIcon extends Vue {
    static cache: Record<string, HTMLImageElement> = {};

    createVehicleTypeActionIcon(vehicleType: VehicleType, action: VehicleAction, enabled = true, size?: number): HTMLImageElement {
        const cacheKey = vehicleType ? UsesVehicleTypeActionIcon.getCacheKey(vehicleType, action, enabled, size) : null;

        if (cacheKey && UsesVehicleTypeActionIcon.cache[cacheKey] !== undefined) {
            return UsesVehicleTypeActionIcon.cache[cacheKey];
        }

        const image = new Image();
        image.src = this.getVehicleTypeActionIconObjectURL(vehicleType, action, enabled, size);

        if (cacheKey) {
            UsesVehicleTypeActionIcon.cache[cacheKey] = image;
        }

        return image;
    }

    getVehicleTypeActionIconObjectURL(vehicleType: VehicleType, action: VehicleAction, enabled = true, size = 30): string {
        const graphIconComponent = new VehicleTypeActionIcon({
            propsData: {
                vehicleType,
                action,
                size,
                enabled,
            },
        });

        graphIconComponent.$mount();

        return URL.createObjectURL(new Blob([graphIconComponent.$el.outerHTML], { type: 'image/svg+xml' }));
    }

    private static getCacheKey(vehicleType: VehicleType, action: VehicleAction, enabled: boolean, size?: number): string {
        return `${vehicleType.id}-${action.name}-${enabled}-${size}`;
    }
}
