
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

export type LelyBreadcrumb = {
    label?: string,
    location?: RawLocation,
    options?: {
        label: string,
        location: RawLocation,
    }[];
};

@Component({})
export default class Breadcrumbs extends Vue {
    @Prop({ required: true })
    breadcrumbs!: LelyBreadcrumb[];

    get root(): RawLocation {
        return this.$route.matched[0];
    }
}
