import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { AuroraState } from '@/store/modules/aurora/auroraState';
import { AuroraImageArea } from '@/core/interfaces/AuroraImageArea';

const getters: GetterTree<AuroraState, RootState> = {
    auroraApiUrl: (state: AuroraState, getters: any, rootState: RootState) => (url: string) => `/aurora/farms/${rootState.farmId}${url}`,

    barnZones: (state: AuroraState) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .sort((a, b) => {
            if (a.calving && !b.calving) return -1;

            if (!a.calving && b.calving) return 1;

            return a.name.localeCompare(b.name);
        }),

    barnZoneById: (state: AuroraState) => (id: number) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .find(barnZone => barnZone.id === id),

    barnZoneByName: (state: AuroraState) => (name: string) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .find(barnZone => barnZone.name === name),

    imageAreasByBarnZone: (state: AuroraState, getters: any) => (barnZoneId: number) => (getters.barnZoneById(barnZoneId).image_areas as AuroraImageArea[])
        .sort((a, b) => a.name.localeCompare(b.name)),

    stitchedImageResponse: (state: AuroraState) => state.stitchedImageResponse,

    imageAreaPreviewResponse: (state: AuroraState) => state.imageAreaPreviewResponse,
};
export default getters;
