import { Component, Vue } from 'vue-property-decorator';
import {
    addSeconds, format, intervalToDuration, startOfDay,
} from 'date-fns';

@Component
export default class UsesDurations extends Vue {
    formatSeconds(seconds: number): string {
        const duration = intervalToDuration({
            start: 0,
            end: seconds * 1000,
        });

        return this.formatDurationShort(duration);
    }

    formatDurationShort(duration: Duration): string {
        if ((duration.years && duration.years > 0) || (duration.months && duration.months > 0)) {
            return '';
        }

        if (duration.days && duration.days > 0) {
            return `
            ${duration.days}d
            ${duration.hours}h
            ${duration.minutes}m`;
        }

        const start = startOfDay(new Date());
        const startPlusSeconds = addSeconds(start, (((duration.hours || 0) * 60 * 60) + (duration.minutes || 0) * 60) + (duration.seconds || 0));

        return format(startPlusSeconds, 'HH:mm');
    }
}
