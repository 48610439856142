import { Module } from 'vuex';
import actions from '@/store/modules/drymow/actions';
import mutations from '@/store/modules/drymow/mutations';
import { RootState } from '@/store/rootState';
import state from '@/store/modules/drymow/state';
import getters from '@/store/modules/drymow/getters';
import { DrymowState } from '@/store/modules/drymow/drymowState';

const drymow: Module<DrymowState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default drymow;
