
import { Component, Vue, Watch } from 'vue-property-decorator';
import EnabledModules from '@/core/interfaces/EnabledModules';
import { Mutation, State } from 'vuex-class';

@Component({})
export default class App extends Vue {
    @State('enabledModules')
    enabledModules!: EnabledModules;

    @State('planningInProgress')
    planningInProgress!: boolean;

    @Mutation('triggerPlanningUpdate')
    triggerPlanningUpdate!: () => void;

    showPlanningToast = false;

    isAppIdle!: boolean;

    get loginPage(): boolean {
        return this.$route.name === 'login';
    }

    @Watch('isAppIdle')
    reloadWhenIdle(): void {
        if (this.isAppIdle) {
            window.location.reload();
        }
    }

    @Watch('planningInProgress')
    public showPlanningFinishedToast(planningInProgress: boolean): void {
        if (this.$route.matched.some(route => route.name === 'new-planning')) {
            return;
        }

        this.showPlanningToast = !planningInProgress;
    }

    reloadPlanning(): void {
        this.$bvToast.hide('planning-updated');

        this.$store.dispatch('syncFarm');

        this.triggerPlanningUpdate();

        this.$router.push({ name: 'overviews.runs' });
    }

    @Watch('enabledModules')
    @Watch('$route')
    validateAccessToPage(): void {
        if (!this.$route.matched.length || !this.enabledModules) {
            return;
        }

        const hasAccess = this.$route.matched
            .map(route => route.name?.split('.'))
            .map(parts => (parts && parts.length > 0 ? parts[0] : null))
            .filter(firstPart => firstPart)
            .some(firstPart => !Object.keys(this.enabledModules)
                .includes(firstPart as string)
                || this.enabledModules[firstPart as keyof EnabledModules]);

        if (!hasAccess) {
            this.$router.push({ name: 'dashboard.overview' });
        }
    }
}

