import { Component, Vue } from 'vue-property-decorator';
import locale from 'date-fns/locale/nl';

@Component
export default class UsesWeekdays extends Vue {
    get weekdayNames(): Record<number, string> {
        return this.weekdayNumbers.reduce((acc: Record<number, string>, weekday) => {
            acc[weekday] = locale.localize?.day(weekday);

            return acc;
        }, {});
    }

    get weekdayNumbers(): number[] {
        return [1, 2, 3, 4, 5, 6, 0];
    }

    get weekdayOptions(): { value: number, text: string }[] {
        return this.weekdayNumbers.map(weekday => ({
            text: this.localizeWeekday(weekday),
            value: weekday,
        }));
    }

    localizeWeekday(weekdayIndex: number): string {
        return locale.localize?.day(weekdayIndex) || '';
    }
}
