import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import router from '@/router';

if (!process.env.VUE_APP_GTM_ID) {
    console.error('env VUE_APP_GTM_ID not set');
}

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID || null,
    vueRouter: router,
});
