import { Component, Vue } from 'vue-property-decorator';
import {
    addDays, differenceInHours, setHours, setMinutes, setSeconds, subSeconds,
} from 'date-fns';

@Component
export default class UsesPeriodWithPlanningStartTime extends Vue {
    getPeriodWithPlanningStartTime(period: { start: Date, end: Date }): { start: Date, end: Date } | null {
        if (!this.$store.getters.planningSettings) {
            return null;
        }

        const nrOfDays = Math.ceil(differenceInHours(period.end, period.start) / 24);
        const [startHours, startMinutes] = (this.$store.getters.planningSettings.planningscycle_starttime || '00:00')
            .split(':')
            .map(Number);
        const start = setSeconds(setMinutes(setHours(period.start, startHours), startMinutes), 0);
        const end = subSeconds(addDays(start, nrOfDays), 1);

        return {
            start,
            end,
        };
    }
}
