import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import VehicleKpiDate from '@/core/interfaces/VehicleKpiDate';
import { KpisState } from '@/store/modules/kpis/kpisState';

const actions: ActionTree<KpisState, RootState> = {
    fetchVehicleKpiDates: async(context: ActionContext<KpisState, RootState>, payload: { dateFrom: string, dateUntil: string }) => {
        const vehicleKpiDates = (await axios.get<VehicleKpiDate[]>(`/web/farms/${context.rootState.farmId}/vehicle-kpis`, {
            params: payload,
        })).data;

        context.commit('setVehicleKpiDates', vehicleKpiDates);
    },

    fetchVehicleStateDurationCategories: async(context: ActionContext<KpisState, RootState>) => {
        const categories = (await axios.get<VehicleKpiDate[]>('/web/vehicle-state-duration-categories')).data;

        context.commit('setVehicleStateDurationCategories', categories);
    },
};

export default actions;
