import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import axios from 'axios';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('./../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    locales.keys()
        .forEach((key) => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                messages[locale] = locales(key);
            }
        });
    return messages;
}

const locale = (window.localStorage.getItem('lely_hmf_locale') === null)
    ? (process.env.VUE_APP_I18N_LOCALE || 'en')
    : window.localStorage.getItem('lely_hmf_locale') || 'en';

const i18n = new VueI18n({
    locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});

export const loadLocaleMessagesFromRemote = async(): Promise<Record<string, string>> => {
    const response = await axios.get(process.env.VUE_APP_TRANSLATIONS_URL as string);

    const locales = response.data.reduce((acc: Record<string, string>, locale: { code: string; name: string }) => {
        acc[locale.code] = locale.name;

        return acc;
    }, {});

    const promisses = response.data.map(async(locale: { code: string }) => {
        const messagesResponse = await axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}/${locale.code}`);
        i18n.mergeLocaleMessage(locale.code, messagesResponse.data);
    });

    await Promise.all(promisses);

    return locales;
};

export default i18n;
