import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { LelyAuth } from '@tec/frontend-vue-shared';

axios.interceptors.response.use((response) => response, (error: any) => {
    if (error.response?.status === 401 && !router.currentRoute.meta?.guest) {
        LelyAuth.logout();

        store.commit('setFarmId', null);
        router.push({ name: 'login' }).then();
    }

    return Promise.reject(error);
});

axios.interceptors.request.use(config => {
    store.commit('setLoading', true);

    return config;
}, error => {
    store.commit('setLoading', false);

    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    store.commit('setLoading', false);

    return response;
}, error => {
    store.commit('setLoading', false);

    return Promise.reject(error);
});
