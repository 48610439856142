import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';
import modules from '@/store/modules';
import createPersistedState from 'vuex-persistedstate';
import getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

Vue.use(Vuex);

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules,
    plugins: [createPersistedState({
        paths: [
            'actions',
            'barns',
            'barnsList',
            'userId',
            'enabledModules',
            'farmPlanners',
            'farmId',
            'farms',
            'farmsList',
            'feedTypes',
            'feedTypesList',
            'fences',
            'fencesList',
            'fencesFeedHeights',
            'locales',
            'presetRuns',
            'rations',
            'rationsList',
            'users',
            'usersList',
            'vehicleTypes',
            'vehicleTypesList',
            'vehicles',
            'vehiclesList',
            'showDebug',
            'exos.activeRuns',
            'exos.birdNests',
            'exos.birds',
            'exos.birdsList',
            'exos.fields',
            'exos.fieldsList',
            'exos.kniveSets',
            'exos.planning',
            'exos.planningList',
            'exos.mowplans',
            'exos.mowplansList',
            'atj.barns',
            'atj.sweepPlanning',
            'runsFilterPreferences',
        ],
    })],
});

export default store;
