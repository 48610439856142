import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { DrymowState } from '@/store/modules/drymow/drymowState';
import Bale from '@/components/modules/drymow/core/types/Bale';
import { parseISO } from 'date-fns';

const weekdayValues = [6, 0, 1, 2, 3, 4, 5];

const getters: GetterTree<DrymowState, RootState> = {
    drymowApiUrl: (state: DrymowState, getters: any, rootState: RootState) => (url: string): string => `/drymow/farms/${rootState.farmId}${url}`,

    balesSet: (state: DrymowState) => state.balesList.map((id: number) => state.bales[id]),

    balesByYear: (state: DrymowState, getters) => (year: number): Bale[] => getters.balesSet
        .filter((bale: Bale) => parseISO(bale.drying_started_at)
            .getFullYear() === year),

    balePlanningSortedByFirstWeekday: (state: DrymowState) => state
        .balePlanning
        .sort((a, b) => {
            const firstWeekdayIndex = a.weekdays.map(weekday => weekdayValues[weekday])
                .sort()[0];
            const secondWeekdayIndex = b.weekdays.map(weekday => weekdayValues[weekday])
                .sort()[0];

            if (firstWeekdayIndex === secondWeekdayIndex) {
                return Number(a.time.replace(':', '')) - Number(b.time.replace(':', ''));
            }

            return firstWeekdayIndex - secondWeekdayIndex;
        }),

};
export default getters;
