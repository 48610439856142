import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { KpisState } from '@/store/modules/kpis/kpisState';
import VehicleKpiRation from '@/core/interfaces/VehicleKpiRation';
import { sumArray } from '@/helpers';
import { format, isToday, parseISO } from 'date-fns';
import VehicleKpiVehicleType from '@/core/interfaces/VehicleKpiVehicleType';
import VehicleStateDuration from '@/core/interfaces/VehicleStateDuration';
import { nl } from 'date-fns/locale';

const getters: GetterTree<KpisState, RootState> = {
    feedingStatisticPerDay: (state: KpisState) => (
        statistic: keyof VehicleKpiRation,
        vehicleTypeId?: number,
        rationId?: number,
        perCow = false,
    ): number[] => state
        .vehicleKpiDates
        .flatMap(date => sumArray(date.vehicle_types
            .filter(vehicleType => (vehicleTypeId ? vehicleType.vehicle_type_id === vehicleTypeId : true))
            .flatMap(vehicleType => vehicleType.rations)
            .filter(ration => (rationId ? ration.ration_id === rationId : true))
            .map(rations => Number(rations[statistic]) / (perCow ? rations.number_of_cows : 1)))),

    vehicleTypeStatisticPerDay: (state: KpisState) => (vehicleTypeId: number, statistic: keyof VehicleKpiVehicleType): number[] => state
        .vehicleKpiDates
        .flatMap(date => sumArray(date.vehicle_types
            .filter(vehicleType => (vehicleType.vehicle_type_id === vehicleTypeId))
            .flatMap(vehicleType => Number(vehicleType[statistic])))),

    vehicleStateDurationsPerDay: (state: KpisState) => (vehicleTypeId: number): VehicleStateDuration[][] => state
        .vehicleKpiDates
        .flatMap(date => date.vehicle_types
            .filter(vehicleType => (vehicleType.vehicle_type_id === vehicleTypeId))
            .map(vehicleType => vehicleType.vehicle_state_durations)),

    startDateByIndex: (state: KpisState) => (dayIndex: number): Date => parseISO(state.vehicleKpiDates[dayIndex].start_date),

    averageFeedingStatistic: (state: KpisState, getters: any) => (statistic: keyof VehicleKpiRation, vehicleTypeId?: number, rationId?: number): number => {
        const perDay: number[] = getters.feedingStatisticPerDay(statistic, vehicleTypeId, rationId);

        perDay.splice(-1);

        if (!perDay.length) {
            return 0;
        }

        return sumArray(perDay) / perDay.length;
    },

    dates: (state: KpisState): string[] => state
        .vehicleKpiDates
        .map(date => format(parseISO(date.start_date), 'EEEEEE dd/MM', {
            locale: nl,
        })),

    dateIdxIsToday: (state: KpisState) => (dateIdx: number): boolean => isToday(parseISO(state.vehicleKpiDates[dateIdx].start_date)),

    vehicleStateDurationCategoryById: (state: KpisState) => (id: number) => state.vehicleStateDurationCategories.find(category => category.id === id),

    vehicleStateDurationCategoriesByVehicleTypeId: (state: KpisState) => (vehicleTypeId: number) => state.vehicleStateDurationCategories
        .filter(category => category.vehicle_type_id === vehicleTypeId),
};
export default getters;
