import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import UsesCanvas from '@/components/shared/mixins/UsesCanvas';
import { BvEvent } from 'bootstrap-vue';

export interface CanvasRectangle {
    x: number,
    y: number,
    w: number,
    h: number;
}

@Component
export default class UsesCanvasRectangles<T> extends mixins(UsesCanvas) {
    private hoverRects: { rect: CanvasRectangle, value: T, pointer?: boolean, action?: string }[] = [];

    protected hovering: { rect: CanvasRectangle, value: T, pointer?: boolean, action?: string } | null = null;

    protected cursor = 'auto';

    mounted(): void {
        this.addTooltipCloseListener();
    }

    registerHoverRectangle(rect: CanvasRectangle, value: T, pointer?: boolean, action?: string): void {
        this.hoverRects.push({
            rect,
            value,
            pointer,
            action,
        });
    }

    clearHoveringRectangles(): void {
        this.hoverRects = [];
    }

    getHoveringRectangle(pageX: number, pageY: number): { value: T, rect: CanvasRectangle, pointer?: boolean, action?: string } | null {
        const canvasX = pageX - this.canvas.getBoundingClientRect().left;
        const canvasY = pageY - this.canvas.getBoundingClientRect().top;

        const result = this.hoverRects
            .find(entry => {
                const { rect } = entry;

                return canvasX >= rect.x && canvasX <= (rect.x + rect.w) && canvasY >= rect.y && canvasY <= (rect.y + rect.h);
            });

        if (result) {
            return result;
        }

        return null;
    }

    handleMouseMove(event: MouseEvent): void {
        this.hovering = this.getHoveringRectangle(event.clientX, event.clientY);
        this.cursor = this.hovering && this.hovering.pointer !== false ? 'pointer' : 'auto';
    }

    handleClick(event: MouseEvent): void {
        const value = this.getHoveringRectangle(event.clientX, event.clientY)?.value;

        if (value) {
            this.$emit('click', value);
        }
    }

    @Watch('hovering')
    moveTooltipTarget(): void {
        if (!this.hovering) {
            return;
        }

        const tooltipTarget = this.$refs['tooltip-target'] as HTMLDivElement;

        if (!tooltipTarget) {
            return;
        }

        tooltipTarget.style.transform = `translate3d(${this.hovering.rect.x}px, ${this.hovering.rect.y}px, 0)`;
        tooltipTarget.style.width = `${this.hovering.rect.w}px`;
        tooltipTarget.style.height = `${this.hovering.rect.h}px`;
    }

    addTooltipCloseListener(): void {
        this.$root.$on('bv::popover::shown', (bvEvent: BvEvent) => {
            if (bvEvent.componentId !== 'tooltip') {
                return;
            }

            const listener = (evt: MouseEvent) => {
                const target = evt.target as HTMLElement;
                if (target.closest('#tooltip') || target.closest('.canvas-container')) {
                    return;
                }

                this.hovering = null;
                this.$root.$emit('bv::hide::popover', bvEvent.componentId);

                document.removeEventListener('mousemove', listener);
            };

            document.addEventListener('mousemove', listener);
        });
    }
}
