import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { ExosState } from '@/store/modules/exos/exosState';
import Field from '@/components/modules/exos/lib/types/Field';
import Planning from '@/components/modules/exos/lib/types/Planning';
import DateHelper from '@/components/modules/exos/lib/DateHelper';
import FeedType from '@/core/interfaces/FeedType';
import Bird from '@/components/modules/exos/lib/types/Bird';
import Mowplan from '@/components/modules/exos/lib/types/Mowplan';
import ActiveRun from '@/components/modules/exos/lib/types/ActiveRun';
import VehicleType from '@/core/interfaces/VehicleType';
import FertilizerTank from '@/core/interfaces/FertilizerTank';

const exosVehicleTypeName = 'exos';

const getters: GetterTree<ExosState, RootState> = {
    exosApiUrl: (state: ExosState, getters: any, rootState: RootState) => (url: string): string => `/exos/farms/${rootState.farmId}${url}`,

    exosFeedTypes: (state: ExosState, getters: any, rootState: RootState, rootGetters: any): FeedType[] => rootGetters
        .feedTypesByVehicleTypeName(exosVehicleTypeName),

    exosVehicles: (state: ExosState, getters: any, rootState: RootState, rootGetters: any): FeedType[] => rootGetters.vehiclesByTypeName(exosVehicleTypeName),

    exosVehicleType: (state: ExosState, getters: any, rootState: RootState, rootGetters: any): VehicleType => rootGetters
        .vehicleTypeByName(exosVehicleTypeName),

    activeFieldsSet: (state: ExosState): Field[] => {
        const fieldIds = new Set(state.activeRuns.map((activeRun: ActiveRun) => activeRun.field_id));

        return Array.from(fieldIds)
            .map((fieldId: any) => state.fields[fieldId]);
    },

    fieldsSet: (state: ExosState) => state.fieldsList.map((id: number) => state.fields[id]),

    planningSet: (state: ExosState): Planning[] => state.planningList.map((id: number) => state.planning[id]),

    fieldById: (state: ExosState) => (id: number): Field => state.fields[id],

    planningById: (state: ExosState) => (id: number): Planning => state.planning[id],

    plannedFieldsSet: (state: ExosState, getters: any): Field[] => {
        const fieldIds = new Set(getters.planningSet.reduce((acc: Array<number>, planning: Planning) => {
            acc.push(planning.field_id);

            return acc;
        }, []));

        return Array.from(fieldIds)
            .map((fieldId: any) => state.fields[fieldId]);
    },

    planningByFeedType: (state: ExosState, getters: any) => (feedTypeId: number): Planning[] => getters.planningSet
        .filter((planning: Planning) => planning.feed_type_id === feedTypeId),

    startingYear: (state: ExosState, getters: any): number => DateHelper.getStartingYear(getters.fieldsSet, 'created_at'),

    birdById: (state: ExosState) => (birdId: number): Bird => state.birds[birdId],

    birdsSet: (state: ExosState): Bird[] => state.birdsList.map((id: number) => state.birds[id])
        .sort((a, b) => a.name.localeCompare(b.name)),

    mowplanById: (state: ExosState) => (mowplanId: number): Mowplan => state.mowplans[mowplanId],

    fertilizationTanks: (state: ExosState) => state.fertilizerTanks,

    activeFertilizationTank: (state: ExosState) => state.fertilizerTanks.find((tank: FertilizerTank) => tank.active) || null,

};
export default getters;
