
import { Component, Prop } from 'vue-property-decorator';
import Run from '@/core/interfaces/Run';
import UsesFarmId from '@/components/shared/mixins/UsesFarmId';
import RunsLogTableRow from '@/components/modules/overviews/components/RunsLogTableRow.vue';
import RunsTableVisits from '@/components/modules/overviews/components/RunsTableVisits.vue';

@Component({
    components: { RunsTableVisits, RunsLogTableRow },
})
export default class RunsTable extends UsesFarmId {
    @Prop({ required: true })
    runs!: Run[];

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: null, type: Number })
    highlightedIndex!: number | null;

    @Prop({ default: true, type: Boolean })
    showButtons!: boolean;

    @Prop({ default: true, type: Boolean })
    showEnergyConsumption!: boolean;

    @Prop({ default: true, type: Boolean })
    fixedHeaders!: boolean;

    updateUrl(item: Run): void {
        this.$router.replace({ query: item?.id ? { run: String(item.id) } : {} });
    }
}

