import Vue from 'vue';
import axios from 'axios';
import IdleVue from 'idle-vue';
import '@tec/frontend-vue-shared/src/assets/lely-red-rules/lely-styling/lely-stylesheet.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import LelyFrontendVueShared, { LelyAuth } from '@tec/frontend-vue-shared';
import initBrowserlogs from '@tec/frontend-vue-shared/src/core/Browserlogs';
import '@/assets/scss/app.scss';
import '@/plugins/BootstrapVue';
import '@/plugins/ChartJs';
import '@/plugins/VueTheMask';
import '@/plugins/gtm';
import '@/filters/date.filters';
import '@/filters/math.filters';
import '@/core/http-interceptor';
import TopNavigation from '@/components/layout/lely/Navigation/TopNavigation.vue';
import PageHeader from '@/components/shared/PageHeader/PageHeader.vue';
import YearSelector from '@/components/shared/YearSelector.vue';
import PageHeaderDetailList from '@/components/shared/PageHeader/PageHeaderDetailList.vue';
import PageHeaderDetailListItem from '@/components/shared/PageHeader/PageHeaderDetailListItem.vue';
import FormButtons from '@/components/shared/FormButtons.vue';
import i18n, { loadLocaleMessagesFromRemote } from './plugins/i18n';
import store from './store';
import router from './router';
import App from './components/App.vue';

Vue.use(LelyFrontendVueShared);

window.dataLayer = window.dataLayer || [];

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';

initBrowserlogs('hmf', () => Number(store.state.userId), () => String(LelyAuth.getAccessToken()));

if ('Cypress' in window) {
    const userId = window.sessionStorage.getItem('lely_hmf_cypress_user_id');

    if (userId) {
        store.commit('setUserId', Number(userId));
    }
}

if (LelyAuth.isAuthenticated()) {
    axios.defaults.headers.common.Authorization = `Bearer ${LelyAuth.getAccessToken()}`;

    store.dispatch('syncFarms')
        .then();

    const farmIdFromLocalStorage = window.localStorage.getItem('lely_hmf_farm_id');
    if (farmIdFromLocalStorage && Object.keys(store.state.farms).includes(farmIdFromLocalStorage)) {
        store.commit('setFarmId', farmIdFromLocalStorage);
        store.dispatch('syncFarm').then();
    } else if (Object.keys(store.state.farms).length) {
        const firstFarmId = Object.keys(store.state.farms)[0];
        window.localStorage.setItem('lely_hmf_farm_id', String(firstFarmId));
        store.commit('setFarmId', firstFarmId);
        store.dispatch('syncFarm').then();
    }
}

loadLocaleMessagesFromRemote()
    .then(locales => store.commit('setLocales', locales));

Vue.component('XLelyTopNavigation', TopNavigation);
Vue.component('XLelyPageHeader', PageHeader);
Vue.component('XLelyPageHeaderDetailList', PageHeaderDetailList);
Vue.component('XLelyPageHeaderDetailListItem', PageHeaderDetailListItem);
Vue.component('XLelyYearSelector', YearSelector);
Vue.component('XLelyFormButtons', FormButtons);

Vue.use(IdleVue, {
    idleTime: 60 * 60 * 1000,
    store,
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

document.title = 'Lely - How\'s my farm ';
