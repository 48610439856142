import { MutationTree } from 'vuex';
import { DrymowState } from '@/store/modules/drymow/drymowState';
import BalePlanning from '@/components/modules/drymow/core/types/BalePlanning';
import Bale from '@/components/modules/drymow/core/types/Bale';
import Vue from 'vue';

const mutations: MutationTree<DrymowState> = {
    setBalePlanning: (state: DrymowState, payload: BalePlanning[]) => {
        state.balePlanning = payload;
    },
    updateBalePlanning: (state: DrymowState, payload: BalePlanning) => {
        const index = state.balePlanning.findIndex(balePlanning => balePlanning.id === payload.id);

        Vue.set(state.balePlanning, index, payload);
    },
    addBalePlanning: (state: DrymowState, payload: BalePlanning) => {
        state.balePlanning.push(payload);
    },
    removeBalePlanning: (state: DrymowState, payload: BalePlanning) => {
        const index = state.balePlanning.findIndex(balePlanning => balePlanning.id === payload.id);

        Vue.delete(state.balePlanning, index);
    },
    setBales: (state: DrymowState, payload: Bale[]) => {
        state.bales = payload.reduce((acc: any, item) => {
            acc[item.id] = { ...acc[item.id], ...item };

            return acc;
        }, {});

        state.balesList = payload.map(item => item.id);
    },

};

export default mutations;
