import { ModuleTree } from 'vuex';
import notifications from '@/store/modules/notifications';
import exos from '@/store/modules/exos';
import { RootState } from '@/store/rootState';
import drymow from '@/store/modules/drymow';
import atj from '@/store/modules/atj';
import afx from '@/store/modules/afx';
import kpis from '@/store/modules/kpis';
import aurora from '@/store/modules/aurora';

const modules: ModuleTree<RootState> = {
    notifications,
    exos,
    drymow,
    atj,
    afx,
    kpis,
    aurora,
};

export default modules;
