import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { DrymowState } from '@/store/modules/drymow/drymowState';
import BalePlanning from '@/components/modules/drymow/core/types/BalePlanning';

let updateBalePlanningAbortController: AbortController | null;

const actions: ActionTree<DrymowState, RootState> = {
    fetchBales: (context: ActionContext<DrymowState, RootState>, year: number) => axios.get(context.getters.drymowApiUrl(`/bales/years/${year}`))
        .then((response) => context.commit('setBales', response.data)),

    fetchBalePlanning: async(context: ActionContext<DrymowState, RootState>) => axios.get(context.getters.drymowApiUrl('/bale-planning'))
        .then((response) => context.commit('setBalePlanning', response.data)),

    removeBalePlanning: async(context: ActionContext<DrymowState, RootState>, payload: BalePlanning) => {
        context.commit('removeBalePlanning', payload);

        axios
            .delete(context.getters.drymowApiUrl(`/bale-planning/${payload.id}`))
            .then(() => context.dispatch('fetchBalePlanning'));
    },

    addBalePlanning: async(context: ActionContext<DrymowState, RootState>, payload: Partial<BalePlanning>) => {
        context.commit('addBalePlanning', payload);

        axios
            .post(context.getters.drymowApiUrl('/bale-planning'), payload)
            .then(() => context.dispatch('fetchBalePlanning'));
    },

    updateBalePlanning: async(context: ActionContext<DrymowState, RootState>, payload: BalePlanning) => {
        context.commit('updateBalePlanning', payload);

        if (updateBalePlanningAbortController) {
            updateBalePlanningAbortController.abort();
        }

        updateBalePlanningAbortController = new AbortController();

        axios
            .put(context.getters.drymowApiUrl(`/bale-planning/${payload.id}`), payload, {
                signal: updateBalePlanningAbortController.signal,
            })
            .then(() => context.dispatch('fetchBalePlanning'));
    },
};

export default actions;
