
import { Component, Prop } from 'vue-property-decorator';
import Run from '@/core/interfaces/Run';
import { Getter } from 'vuex-class';
import Vehicle from '@/core/interfaces/Vehicle';
import { differenceInMinutes, differenceInSeconds, parseISO } from 'date-fns';
import UsesDurations from '@/components/shared/mixins/UsesDurations';
import Fence from '@/core/interfaces/Fence';
import VehicleTypeActionIcon from '@/components/shared/VehicleTypeActionIcon.vue';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleAction from '@/core/interfaces/VehicleAction';
import FenceVisitAction from '@/core/enums/FenceVisitAction';

@Component({
    components: { VehicleTypeActionIcon },
})
export default class RunsLogTableRow extends UsesDurations {
    @Prop()
    public run!: Run;

    @Prop({ required: true })
    public isExpanded!: boolean;

    @Prop({ required: false })
    public highlighted!: boolean;

    @Prop({ type: Boolean, default: true })
    public showEnergyConsumption!: boolean;

    @Getter('vehicleTypeById')
    public vehicleTypeById!: (id: number) => VehicleType;

    @Getter('vehicleById')
    public vehicleById!: (id: number) => Vehicle;

    @Getter('fenceById')
    public fenceById!: (id: number) => Fence;

    @Getter('actionById')
    public actionById!: (id: number) => VehicleAction;

    get vehicle(): Vehicle | null {
        return this.vehicleById(this.run.vehicle_id) || null;
    }

    get ongoing(): boolean {
        if (this.scheduled && !this.run.estimated_completed_at) {
            return true;
        }

        return !this.scheduled && !this.run.completed_at;
    }

    get duration(): number | null {
        if (this.ongoing) {
            return null;
        }

        if (this.run.estimated_completed_at) {
            return differenceInSeconds(parseISO(this.run.estimated_completed_at), parseISO(this.run.started_at));
        }

        return differenceInSeconds(parseISO(this.run.completed_at), parseISO(this.run.started_at));
    }

    get fed(): number {
        return this.run.fence_visits
            .map(fenceVisit => fenceVisit.feed_visits
                .reduce((acc, feedVisit) => acc + feedVisit.actual_kg, 0))
            .reduce((acc, kgs) => acc + kgs, 0) || 0;
    }

    get requested(): number {
        return this.run.fence_visits
            .map(fenceVisit => fenceVisit.feed_visits
                .reduce((acc, feedVisit) => acc + feedVisit.requested_kg, 0))
            .reduce((acc, kgs) => acc + kgs, 0) || 0;
    }

    get actions(): VehicleAction[] {
        return [...new Set(this.run.fence_visits.map(fenceVisit => this.actionById(fenceVisit.action_id)) || [])];
    }

    get scheduled(): boolean {
        return this.run.id === null;
    }

    get inMinutes(): number {
        return differenceInMinutes(parseISO(this.run.started_at), new Date());
    }

    get showCountdown(): boolean {
        return this.scheduled && this.inMinutes < 60 && this.inMinutes > -1;
    }

    get numberOfFencesFed(): number {
        return this.run
            .fence_visits
            .filter(fenceVisit => this.$store.getters.actionById(fenceVisit.action_id).name === FenceVisitAction.FEED)
            .length;
    }
}

