
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component
export default class LocaleSelect extends Vue {
    @State('locales')
    private locales!: Record<string, string>;

    get currentLocale(): string {
        return this.locales[this.$i18n.locale];
    }

    get availableLocales(): [string, string][] {
        return Object.entries(this.locales);
    }

    setLocale(key: string): void {
        this.$i18n.locale = key;
        window.localStorage.setItem('lely_hmf_locale', key);
    }
}

