
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import FeedType from '@/core/interfaces/FeedType';
import FeedVisit from '@/core/interfaces/FeedVisit';

@Component({})
export default class FenceVisitFeedInfo extends Vue {
    @Prop({ required: true, type: Array })
    feedVisits!: FeedVisit[];

    @Getter('feedTypeById')
    public feedTypeById!: (id: number) => FeedType;

    @Prop({ type: Boolean })
    public scheduled!: boolean;

    get feedVisitsSorted(): FeedVisit[] {
        return this.feedVisits.sort((a, b) => (this.scheduled ? b.requested_kg - a.requested_kg : b.actual_kg - a.actual_kg));
    }
}
