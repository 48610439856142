
import { Component, Prop, Vue } from 'vue-property-decorator';
import { addDays } from 'date-fns';
import { Getter } from 'vuex-class';
import VehicleType from '@/core/interfaces/VehicleType';

export interface RunsFiltersData {
    date?: Date;
    vehicleTypeIds: number[];
    showRunsWithoutFeeding: boolean;
    usePlanningStartTime: boolean;
}

@Component({})
export default class RunsFilters extends Vue {
    @Prop({
        required: true,
    })
    value!: RunsFiltersData;

    @Prop({
        type: Boolean,
        default: true,
    })
    showDate!: boolean;

    @Prop({
        type: Array,
        default: null,
    })
    showVehicleTypes!: VehicleType[];

    @Getter('vehicleTypesWithActions')
    vehicleTypesWithActions!: VehicleType[];

    get maxDate(): Date {
        return addDays(new Date(), 1);
    }

    get date(): Date {
        return this.value.date || new Date();
    }

    set date(date: Date) {
        this.$emit('input', {
            ...this.value,
            date,
        });
    }

    get vehicleTypeIds(): number[] {
        return this.value.vehicleTypeIds || [];
    }

    set vehicleTypeIds(vehicleTypeIds: number[]) {
        this.$emit('input', {
            ...this.value,
            vehicleTypeIds,
        });
    }

    get showRunsWithoutFeeding(): boolean {
        return this.value.showRunsWithoutFeeding;
    }

    set showRunsWithoutFeeding(showRunsWithoutFeeding: boolean) {
        this.$emit('input', {
            ...this.value,
            showRunsWithoutFeeding,
        });
    }

    get usePlanningStartTime(): boolean {
        return this.value.usePlanningStartTime;
    }

    set usePlanningStartTime(usePlanningStartTime: boolean) {
        this.$emit('input', {
            ...this.value,
            usePlanningStartTime,
        });
    }

    get planningStartTime(): string {
        return this.$store.getters.planningSettings?.planningscycle_starttime || '00:00';
    }

    get vehicleTypeOptions(): VehicleType[] {
        return this.showVehicleTypes || this.vehicleTypesWithActions;
    }
}

