import { Component } from 'vue-property-decorator';
import UsesCanvas from '@/components/shared/mixins/UsesCanvas';
import {
    differenceInHours, differenceInMinutes, endOfToday, startOfToday,
} from 'date-fns';

@Component
export default class UsesCanvasPeriod extends UsesCanvas {
    getXPixelForTime(timeOrHours: string | number | Date, minutesInput?: number): number {
        let date = startOfToday();

        if (timeOrHours && minutesInput) {
            date.setHours(Number(timeOrHours));
            date.setMinutes(Number(minutesInput));
        } else if (timeOrHours instanceof Date) {
            date = timeOrHours;
        } else {
            const [hours, minutes] = String(timeOrHours).split(':').map(i => Number(i));
            date.setHours(hours);
            date.setMinutes(minutes);
        }

        const minutesDiff = differenceInMinutes(this.start, date);
        return this.leftMargin - Math.round(this.minutesPerXPixel * minutesDiff);
    }

    get minutesPerXPixel(): number {
        return this.graphWidth / differenceInMinutes(this.end, this.start);
    }

    get graphWidth(): number {
        return this.width - this.leftMargin - 20;
    }

    get leftMargin(): number {
        return 0;
    }

    get start(): Date {
        return startOfToday();
    }

    get end(): Date {
        return endOfToday();
    }

    get daysInChart(): number {
        return Math.ceil(differenceInHours(this.end, this.start) / 24);
    }
}
