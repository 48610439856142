import { MutationTree } from 'vuex';
import Notification from '@/core/interfaces/Notification';
import { NotificationState } from '@/store/modules/notifications/notificationState';
import Vue from 'vue';

const mutations: MutationTree<NotificationState> = {
    add(state, notification: Notification) {
        state.notifications.push(notification);
    },
    remove(state, notification: Notification) {
        Vue.delete(state.notifications, state.notifications.indexOf(notification));
    },
    clear(state) {
        state.notifications = [];
    },
};

export default mutations;
