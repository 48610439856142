import { differenceInDays, format, startOfDay } from 'date-fns';
import i18n from '@/plugins/i18n';

export default class DateHelper {
    static getStartingYear<T extends Record<string, any>>(values: T[], key: keyof T): number {
        const currentYear = new Date().getFullYear();

        return values.reduce((acc: number, field: T) => {
            if (!field.hasOwnProperty(key)) {
                return acc;
            }

            const fieldCreationDate = new Date(field[key]).getFullYear();

            return fieldCreationDate < acc ? fieldCreationDate : acc;
        }, currentYear);
    }

    static formatDateTimeOrShorthand(date: Date | string, formatString = 'dd/MM HH:mm'): string {
        const dateObj = typeof date === 'string' ? new Date(date) : date;

        const differenceInDaysBetweenNowAndStart = differenceInDays(startOfDay(dateObj), startOfDay(new Date()));

        if (differenceInDaysBetweenNowAndStart === 0) {
            return `${i18n.t('general.today')} ${format(dateObj, 'HH:mm')}`;
        } if (differenceInDaysBetweenNowAndStart === 1) {
            return `${i18n.t('general.tomorrow')} ${format(dateObj, 'HH:mm')}`;
        }

        return format(dateObj, formatString);
    }

    static formatDate(date: Date | string, formatString = 'dd MMM yyyy'): string {
        const dateObj = typeof date === 'string' ? new Date(date) : date;

        return format(dateObj, formatString);
    }

    static formatDateTime(date: Date | string, formatString = 'dd MMM yyyy HH:mm'): string {
        const dateObj = typeof date === 'string' ? new Date(date) : date;

        return format(dateObj, formatString);
    }
}
