import { Module } from 'vuex';
import actions from '@/store/modules/kpis/actions';
import mutations from '@/store/modules/kpis/mutations';
import { RootState } from '@/store/rootState';
import state from '@/store/modules/kpis/state';
import getters from '@/store/modules/kpis/getters';
import { KpisState } from '@/store/modules/kpis/kpisState';

const kpis: Module<KpisState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default kpis;
