
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Fence from '@/core/interfaces/Fence';
import Run from '@/core/interfaces/Run';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleTypeActionIcon from '@/components/shared/VehicleTypeActionIcon.vue';
import VehicleAction from '@/core/interfaces/VehicleAction';
import FenceVisitFeedInfo from '@/components/shared/FenceVisitFeedInfo.vue';
import FenceVisit from '@/core/interfaces/FenceVisit';
import FenceVisitAction from '@/core/enums/FenceVisitAction';

@Component({
    components: { FenceVisitFeedInfo, VehicleTypeActionIcon },
})
export default class RunsTableVisits extends Vue {
    @Prop()
    public run!: Run;

    @Prop({ type: Boolean })
    public scheduled!: boolean;

    @Prop({})
    public fenceId?: number;

    @Getter('vehicleById')
    public vehicleById!: (id: number) => Vehicle;

    @Getter('vehicleTypeById')
    public vehicleTypeById!: (id: number) => VehicleType;

    @Getter('fenceById')
    public fenceById!: (id: number) => Fence;

    @Getter('actionById')
    public actionById!: (id: number) => VehicleAction;

    get vehicle(): Vehicle {
        return this.vehicleById(this.run.vehicle_id);
    }

    get totalKgs(): number[] {
        return this.run.fence_visits
            .map(fenceVisit => fenceVisit.feed_visits
                .map(feedVisit => (feedVisit.actual_kg ? feedVisit.actual_kg : feedVisit.requested_kg))
                .reduce((a, b) => a + b, 0));
    }

    get feedingVisits(): FenceVisit[] {
        return this.run.fence_visits.filter(fenceVisit => this.$store.getters.actionById(fenceVisit.action_id).name === FenceVisitAction.FEED);
    }

    get nonFeedingVisits(): FenceVisit[] {
        return this.run.fence_visits.filter(fenceVisit => this.$store.getters.actionById(fenceVisit.action_id).name !== FenceVisitAction.FEED);
    }
}

