
import { Component, Prop, Vue } from 'vue-property-decorator';
import VehicleType from '@/core/interfaces/VehicleType';
import FenceVisitAction from '@/core/enums/FenceVisitAction';
import VehicleTypeColorService, { VehicleTypeColor } from '@/core/VehicleTypeColorService';
import VehicleAction from '@/core/interfaces/VehicleAction';

@Component({})
export default class VehicleTypeActionIcon extends Vue {
    @Prop({ default: null })
    vehicleType!: VehicleType;

    @Prop({ required: true })
    action!: VehicleAction;

    @Prop({ default: 30 })
    size!: number;

    @Prop({ default: true, type: Boolean })
    enabled!: boolean;

    fenceVisitAction = FenceVisitAction;

    get fillColor(): string {
        return this.color.fill;
    }

    get strokeColor(): string {
        return this.color.stroke;
    }

    get color(): VehicleTypeColor {
        return VehicleTypeColorService.get(this.vehicleType);
    }

    get opacity(): string {
        return this.enabled ? '1' : '0.5';
    }
}
