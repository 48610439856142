import { ActionContext, ActionTree } from 'vuex';
import { ExosState } from '@/store/modules/exos/exosState';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import Planning from '@/components/modules/exos/lib/types/Planning';
import FertilizerTank from '@/core/interfaces/FertilizerTank';

const actions: ActionTree<ExosState, RootState> = {
    sync: (context: ActionContext<ExosState, RootState>) => {
        context.dispatch('fetchFields');
        context.dispatch('fetchPlanning');
        context.dispatch('fetchActiveRuns');
        context.dispatch('fetchBirds');
        context.dispatch('fetchBirdNests');
        context.dispatch('fetchMowplans');
        context.dispatch('fetchFertilizerTanks');
    },

    fetchFields: (context: ActionContext<ExosState, RootState>) => axios.get(context.getters.exosApiUrl('/fields'))
        .then((response) => {
            context.commit('setFields', response.data);
        }),

    fetchPlanning: async(context: ActionContext<ExosState, RootState>) => axios.get(context.getters.exosApiUrl('/planning'))
        .then((response) => {
            context.commit('setPlanning', response.data);
        }),

    fetchActiveRuns: async(context: ActionContext<ExosState, RootState>) => {
        const response = await axios.get(context.getters.exosApiUrl('/active-runs'));
        context.commit('setActiveRuns', response.data);
    },

    addFieldToPlanning: async(
        context: ActionContext<ExosState, RootState>,
        payload: {
            field_id: number,
            feed_type_id: number,
            active_mowplan_id: number,
            order: number,
            kg_nitrogen_per_hectare: number,
            fertilize: boolean,
        },
    ): Promise<void> => {
        await axios.post(context.getters.exosApiUrl('/planning'), payload);

        await Promise.all([
            context.dispatch('fetchPlanning'),
            context.dispatch('fetchFields'),
        ]);
    },

    updatePlanning: async(
        context: ActionContext<ExosState, RootState>,
        payload: Partial<Planning>,
    ): Promise<void> => {
        await axios.patch(context.getters.exosApiUrl(`/planning/${payload.id}`), payload);

        await Promise.all([
            context.dispatch('fetchPlanning'),
            context.dispatch('fetchFields'),
        ]);
    },

    movePlanning: async(context: ActionContext<ExosState, RootState>, payload: { planning_id: number, feed_type_id: number, order: number }) => {
        const response = await axios.patch(context.getters.exosApiUrl(`/planning/${payload.planning_id}`), {
            feed_type_id: payload.feed_type_id,
            order: payload.order,
        });

        await context.dispatch('fetchPlanning');

        return response.data;
    },

    completeCut: async(context: ActionContext<ExosState, RootState>, cutId: number) => {
        await axios.patch(context.getters.exosApiUrl(`/cuts/${cutId}`), { completed_at: new Date().toISOString() });

        await Promise.all([
            context.dispatch('fetchFields'),
            context.dispatch('fetchPlanning'),
        ]);
    },

    destroyPlanning: async(context: ActionContext<ExosState, RootState>, id: string) => {
        await axios.delete(context.getters.exosApiUrl(`/planning/${id}`));

        await Promise.all([
            context.dispatch('fetchFields'),
            context.dispatch('fetchPlanning'),
        ]);
    },

    fetchBirdNests: async(context: ActionContext<ExosState, RootState>) => axios.get(context.getters.exosApiUrl('/bird-nests'))
        .then((response) => {
            context.commit('setBirdNests', response.data);
        }),

    fetchBirds: async(context: ActionContext<ExosState, RootState>) => axios.get('exos/birds')
        .then((response) => {
            context.commit('setBirds', response.data);
        }),

    fetchMowplans: async(context: ActionContext<ExosState, RootState>) => axios.get(context.getters.exosApiUrl('/active-mowplans'))
        .then((response) => {
            context.commit('setMowplans', response.data);
        }),

    fetchFertilizerTanks: async(context: ActionContext<ExosState, RootState>) => axios.get(context.getters.exosApiUrl('/fertilizer-tanks'))
        .then((response) => {
            context.commit('setFertilizerTanks', response.data);
        }),

    saveFertilizerTank: async(context: ActionContext<ExosState, RootState>, payload: Partial<FertilizerTank>) => {
        let xhr;
        if (payload.id) {
            xhr = axios.put(context.getters.exosApiUrl(`/fertilizer-tanks/${payload.id}`), payload);
        } else {
            xhr = axios.post(context.getters.exosApiUrl('/fertilizer-tanks'), payload);
        }

        await xhr;

        await context.dispatch('fetchFertilizerTanks');
    },

    activateFertilizerTank: async(context: ActionContext<ExosState, RootState>, payload: FertilizerTank) => {
        await axios.patch(context.getters.exosApiUrl(`/fertilizer-tanks/${payload.id}`), {
            active: true,
        });

        await context.dispatch('fetchFertilizerTanks');
    },

    setFertilizerTankCurrentLevel: async(context: ActionContext<ExosState, RootState>, payload: {
        fertilizerTank: FertilizerTank,
        newLevelLiters: number
    }) => {
        await axios.patch(context.getters.exosApiUrl(`/fertilizer-tanks/${payload.fertilizerTank.id}`), {
            current_level_liters: payload.newLevelLiters,
        });

        await context.dispatch('fetchFertilizerTanks');
    },

    resetFertilizerTankCurrentLevel: async(context: ActionContext<ExosState, RootState>, payload: FertilizerTank) => {
        await context.dispatch('setFertilizerTankCurrentLevel', {
            fertilizerTank: payload,
            currentLevelLiters: payload.capacity_liters,
        });
    },

    deleteFertilizerTank: async(context: ActionContext<ExosState, RootState>, payload: number) => {
        await axios.delete(context.getters.exosApiUrl(`/fertilizer-tanks/${payload}`));
        await context.dispatch('fetchFertilizerTanks');
    },
};

export default actions;
