// eslint-disable-next-line import/prefer-default-export
export const round = (num: number, precision = 1): number => Number(num.toFixed(precision));

export const groupByKey = <T>(arr: T[], attribute: keyof T): Record<string, T[]> => {
    const result: Record<string, T[]> = {};

    arr.forEach(item => {
        const key = String(item[attribute]);

        if (result.hasOwnProperty(key)) {
            result[key].push(item);
        } else {
            result[key] = [item];
        }
    });

    return result;
};

export const groupByCallback = <T>(arr: T[], callback: (item: T) => string): Record<string, T[]> => {
    const result: Record<string, T[]> = {};

    arr.forEach(item => {
        const key = String(callback(item));

        if (result.hasOwnProperty(key)) {
            result[key].push(item);
        } else {
            result[key] = [item];
        }
    });

    return result;
};

export const groupByReducer = <T, V>(record: Record<string, T[]>, callback: (item: T[]) => V): Record<string, V> => Object
    .entries(record)
    .reduce((acc: Record<string, V>, item) => {
        acc[item[0]] = callback(item[1]);

        return acc;
    }, {});

export const sumArray = (arr: number[]): number => arr.reduce((acc, value) => acc + value, 0);

export const toUrlArrayParam = (array: any[], name: string): string => new URLSearchParams(array
    .reduce((acc: Record<string, string>, item: any, idx: number) => {
        acc[`${name}[${idx}]`] = String(item);

        return acc;
    }, {})).toString();
