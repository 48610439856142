
import { Component, Vue, Watch } from 'vue-property-decorator';
import Farm from '@/core/interfaces/Farm';
import { Getter } from 'vuex-class';
import LelyNavigationModuleSwitcher from '@/components/layout/lely/Navigation/ModuleSwitcher.vue';
import { BDropdown } from 'bootstrap-vue';
import LocaleSelect from '@/components/shared/LocaleSelect.vue';
import { LelyApp, LelyAuth } from '@tec/frontend-vue-shared';

@Component({
    components: {
        LocaleSelect,
        LelyNavigationModuleSwitcher,
    },
})
export default class TopNavigation extends Vue {
    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('isLoading')
    isLoading!: boolean;

    delayedLoading = false;

    loadingTimer = 0;

    switchFarm = 0;

    get farm(): Farm {
        return this.$store.getters.farm;
    }

    switchFarmId(farmId: number): void {
        this.$store.commit('setFarmId', farmId);
        this.$store.dispatch('syncFarm');

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.push({ path: this.$route.matched[0].path }).catch(() => {
        });

        this.switchFarm = 0;

        (this.$refs.dropdown as BDropdown).hide();
        window.localStorage.setItem('lely_hmf_farm_id', String(farmId));
    }

    logout(): void {
        this.$store.commit('clear');
        LelyAuth.logout();

        this.$router.push({ name: 'login' });
    }

    @Watch('isLoading')
    watchLoading(): void {
        if (!this.isLoading) {
            window.clearTimeout(this.loadingTimer);
            this.loadingTimer = 0;
            this.delayedLoading = false;
            return;
        }

        this.loadingTimer = window.setTimeout(() => {
            if (this.loadingTimer) {
                window.clearTimeout(this.loadingTimer);
                this.loadingTimer = 0;
            }

            this.loadingTimer = 0;
            this.delayedLoading = this.isLoading;
        }, 500);
    }

    get app(): LelyApp {
        if (window.location.href.includes('hmf-acc')) {
            return LelyApp.HMF_ACC;
        }

        return LelyApp.HMF;
    }
}

